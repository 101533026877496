
/* Mikes Styles */
.container {
    max-width: 1200px;
    width: 90%!important;
        .row {
            margin-left: auto;
            margin-right: auto;
        }
}
.padding {
    padding: 3%!important;
}

/* Page Header Styles */
.page-header {
    height: 672px!important;
    h1 {
        color: #fff;
        font-size: 60px;
        margin: 0;
        padding: 0;
        text-shadow: 6px 4px 6px rgba(0,0,0,.79), -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }
    h2 {
        color: #fff;
        font-size: 40px;
        margin: 0;
        padding: 0;
        text-shadow: 6px 4px 6px rgba(0,0,0,.79), -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        margin-bottom: 5%;
    }
    span.links {
        a {
            background-color: rgba(0,0,0,.5);
            padding: 5px;
            display: inline-block;
            color: #C1C1D0;
            text-decoration: none;
            webkit-border-radius: 2px;
            border-radius: 2px;
            margin: 5px 0;
            transition:.25s all ease-in-out;
            font-size: 28px;
                &:hover{
                    background-color: rgba(0,0,0,.75);
                }
        }
    }
    .description{
        background-color: rgba(0,0,0,.5);
            .description-text{
                p{
                    color: #fff;
                    font-size: 21px;
                    padding-bottom: 20px;
                }
            }
            .responsive-img{
                margin: 22px auto;
                display: block;
                padding-bottom: 20px;
            }
    }
}

.blog-header {
    @extend .page-header;
    max-height: 568px;

    @media (max-width: $breakpoint-phone) {
        h1 {
            font-size: 36px !important;
            letter-spacing: 0.5px;
            text-align: left;
        }
        .us-header-text {
            font-size: 24px !important;
            @media (max-width: $breakpoint-phone) {
                font-size: 19px !important;
            }
        }
    }

    p.meta {
        text-shadow: 6px 4px 6px rgba(0,0,0,.79), -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        color: #fff;
    }

    .description {
        padding: 15px 0px;
        background-color: rgba(3, 9, 25, 0.7);
    }
}
/* END Page Header Styles */

/* Page contact form Styles */
.page-contact-form{
    .contact-form-heading{
        text-align: left;
        padding-left: 22px;

    }
    input[type=submit]{
        border-radius: 3px !important;
        margin-left: 1%;
    }

    background-color: #2a2d33;
    position: relative;
    margin-bottom: 64px;

    @media (max-width: $breakpoint-ipad + 220) {
	     margin-top: 25px;
	}

    .green-contact-top {
        background-color:#7aa87e;
        text-align: center;
        padding: 10px 0 0 0;
            img {
                max-width: 75px;
            }
    }
    .contact-form-heading {
        color: #999;
        text-align: center;
        font-weight: 800;
        font-size: 50px;
    }
    p {
        padding: 0 12%;
        text-align: center;
        color: #e3e3e3;
    }
    label {
       display: none!important;
    }
}

.no-negative-margin {
    margin: initial !important;
}

/* Page contact form Styles */

/* Top left body content on all pages */
.top-left-text, .feature-text {
    font-size: 27px;
    font-family: 'industry', sans-serif;
}
.top-left-text {
    margin-top: 3%;
}
/* END Top left body content on all pages */

/* full width gray background content area */
.light-gray-bg{
    background-color: #e3e3e3;
    padding: 4% 0;
    margin: 3% 0;
}
/* end full width gray background content area */

/* Full width parallax body content Type 1 */
.flex-baseline {
   display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
    -webkit-align-items: flex-end;
	-moz-align-items: flex-end;
	-ms-align-items: flex-end;
	align-items: flex-end;
    padding-bottom: 3%;
        .opaque-black{
        background-color: rgba(0,0,0,.82);
        color: #fff;
        width: 90%;
        margin: 0 auto;
        }
}
/* END Full width parallax body content type 1 */

/* Start Full width parallax body content Type 2 */
.right-parallax-2 {
    //padding: 40px 25% 40px 0;
    text-shadow: 1px 1px 0 #000;
    font-size: 25px;
    @media (max-width: $breakpoint-after-ipad) {
        padding: 4%;
    }
    h2 {
        color: #7EA97E;
        font-size: 45px;
    }
    p {
        color: #fff;
        line-height: 1.3;
    }
}
/* End Full width parallax body content Type 2 */

/* Start Full, 2 wide image and text */

.row {
    .flex-center {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: #443334;
        .no-padd {
            padding: 0!important;
                img {
                    display:block;
                }
        }
        .max-width-full {
            max-width: 824px;
            color: #fff;
            padding-left: 3%;
        }
    }

}

/* Start Full, 2 wide image and text */

/* Start full width 2/3 plus 1/3 side by side */
.row {
    .flex {
        display: -webkit-flex;
    	display: -moz-flex;
    	display: -ms-flex;
    	display: flex;
    	 @media (max-width: $breakpoint-ipad) {
    	  display: block;
    	 }
    	    .l7 {
    	        margin-right: 1%;
    	        @media (max-width: $breakpoint-ipad) {
    	          margin: 0;
    	         }
    	            .max-width-full {
    	                width: 100%;
                        max-width: 824px;
                        float: right;
                    }
    	    }
    	    .l6 {
    	         @media (max-width: $breakpoint-max-width) {
    	             width: 100%;
    	         }
    	    }

    }
}

/* END full width 2/3 plus 1/3 side by side */

/* Start 4 wide */
.four-wide-wrap{

    .four-wide{
        width: 25%;
        float: left;
        box-sizing: border-box;
        padding: 0 0.75rem;
         @media (max-width: $breakpoint-max-width) {
        width: 50%;
        }
        @media (max-width: $breakpoint-phone) {
        width: 100%;
        }
    }
}
/* End 4 wide */

/* Start Big image call out with large text next to it */
.blue-large-text{
    color: $swanson-blue;
    font-size: 37px;
    line-height: 1.2;
    font-family: $heading-font;
    margin-top: 0;
     @media (max-width: $breakpoint-max-width) {
        font-size: 23px;
        }
    @media (max-width: $breakpoint-ipad) {
        font-size: 19px;
        }
}
/* End Big image call out with large text next to it */

/* Full width, one column, large image with text below */
.container {
    .row {
        .product-large {
            p {
                max-width: 850px;
                color: #999;
            }
        }
    }
}
/* END Full width, one column, large image with text below */

/* three wide with videos */
.container {
    .row {
        .video-3-wide {
            h3 {
                color: #95989A;
            }
        }
    }
}
/* End three wide with videos */

/* Start Large background picture with overlay text try1 */

.row.large-bg {
    background-image: url('/wp-content/uploads/2017/04/TESTIMAGE.jpg');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    padding-top: 8%;
        .text-overlay {
            margin-bottom: 39%;
            background-color: rgba(234,234,234,.85);
        }
}

/* End Large background picture with overlay text try1 */

/* Start Large background picture with overlay text try2 */

.heightcontrol.parallax-container{
    height: 895px;
    padding-top: 8%;
        .text-overlay {
            margin-bottom: 39%;
            background-color: rgba(234,234,234,.85);
        }
}

/* End Large background picture with overlay text try2 */

/* End Mikes Styles */

.page-layouts {
    .row {
        margin: 0 auto;
    }

    img {
        @extend .responsive-img;
    }
    .blog-header .description img {
        margin: 0 auto!important;
        padding-bottom: 0;
    }
}

// left parallax hide
body.page-id-389, body.page-id-446, body.page-id-1502{
    .leftparallax{
        display: none;
    }
}

// industrial chrome plating
body.page-id-389 {
    .contact-change{
        margin-top: 0;
    }
}

.icp-border{
    border-bottom:1px solid #f19524;
    padding: 2% 0;
}
.icp-orange{
    color:#f19524;
    font-weight: 800;
}
.icp-border:last-child(){
    border: none;
}
.icp-right{

}
.vertical-flex{
    display: flex;
    vertical-align: middle;
    align-items: center;
}
// section styles
.section-pad{
    padding: 3.5% 0;
}
.top-section {
    padding: 75px 0px 50px 0px !important;

    .max-500 {
        @media (min-width: $breakpoint-992) {
            max-width: 500px;
        }
    }

    p {
        margin: 0 !important;
    }
}
//
.icp-border-right{
    border-right: 40px solid #7EA97E;
    padding-left: 4%!important;
}
.induction-bg {
    background-image: url(/wp-content/uploads/2017/07/induction-bkg-text.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 7%;
    background-size: 100%;
    h4{
        margin-top:25px;
    }
}
.page-icon{
    float: left;
    max-width: 70px!important;
    @media (max-width: $breakpoint-after-ipad) {
    	margin: 24px auto 0;
        display: block;
        float:none;
	}
    @media (min-width: 768px) {
        padding-right:14px;
    }
    @media (min-width: $breakpoint-small) {
        max-width: 120px!important;
    }
}

body.page-id-60{
    .contact-change{
        margin-top: 0%;
    }
}

.intro-text {
    .intro-header {
        font-weight:700 !important;
        font-size: 42px !important;
        line-height: 1.2;
    }

    padding-top: 42px;
    line-height: 1.75;

    @media #{$medium-and-up} {
        padding-right: 15px !important;
    }
}

// contact page overwrites
body[data-slug="contact"] {
    .sticky-contact {
        display: none;
    }
}