.bta-parallax, .bta-parallax-single {
     width: 100%;
     background-size: cover;
     position:relative;
     padding-top:25%;

     .header-content-wrap {
         color: #fff;
         position: relative;
        h1 {
            color: #fff;
            text-shadow: 2px 2px 0 #000;
            font-size: 50px;
            text-align: left;
            margin-top: 30px;

            @media (max-width: $breakpoint-phone) {
                font-size: 36px;
                letter-spacing: 0.5px;
                text-align: center;
                margin-top: 10px;
            }
        }
        .description{
            background-color: rgba(42, 45, 51, 0.76);
            padding: 1% 0;
            p.meta {
                font-size: 17px;
                text-align: center;
                margin-top: 0;
            }
            a {
                margin-top: 10px;
            }
        }
    }
    #screen {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }
    .row{
        margin-bottom:0;

    }
}

.parallax-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (min-width: $breakpoint-ipad) {
        background-image: none !important;
    }
}