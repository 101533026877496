[data-scroll] {
    opacity: 0;
    transition: all 0.3s ease;
}

.btn-floating {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.53);

    i {
        font-size: 1rem;
    }

    &:hover {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.53);
    }
}

.fixed-action-btn {
    right: 10px;
    bottom: 10px;
}

.fadeOut {
    opacity: 0;
}

.fadeIn {
    opacity: 1;
}
