#feedback{
			position: fixed;
			top:100px;
			left:-362px;
		}
		#feedback form{
			float: left;
			border: 1px solid #999;
			padding: 10px;
			border-radius:5px;
		}
		#feedback form label{
			display: block;
			float: left;
			text-align:right;
			margin-right:20px;
			width:70px;
			font-weight:bold;
			color: 019AC6;
			font-size:14px;
		}
		#feedback div{
			background:url("feedback-buttons.png")  no-repeat center left transparent;
			display: block;
			width:40px;
			height:175px;
			float: left;
			text-indent:-9999em;
			position: absolute;
			top:120px;
			right:-40px;
		}
		#feedback a:hover{
			background-position: center right;
		}
		#feedback form .btn{
			float: right;
			background:url("send.png")  no-repeat 0px 0px transparent;
			text-indent:-9999em;
			width:80px;
			height:28px;
			border:none;

		}
		#feedback form textarea{
			width:250px;
			height:140px;
			border: 1px solid #019AC6;
		}
		#feedback form input[type="text"]{
			width:250px;
			height:30px;
			border: 1px solid #019AC6;
		}
		#feedback h2{
			text-align:center;
			color: #019AC6;
			font-size:22px;
		}