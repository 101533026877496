.page-template-page-cylinder-xpress {
    .col {
        margin-left: 0 !important;
    }

    .container-reg {
        @extend .container;
    }

    .container {
        max-width: 1600px;
    }

    .width-5 {
        width: 20%;

        @media #{$small-and-down} {
            width: 100%;
        }
    }

    .wrap-dd-select {
        width: 100% !important;
    }

    ul.collection, ul.dropdown {
        list-style-type: none !important;
        padding: 0 !important;
    }

    .modal-close-button {
        @extend .z-depth-2;
        width: 48px !important;
        line-height: 48px !important;
        height: 48px !important;
        font-size: 36px !important;
        vertical-align: middle !important;
        color: #fff !important;
        text-align: center !important;
        border-radius: 50% !important;
        background-color: $secondary-color;
        z-index: 1004;
        position: absolute;
        top: -16px;
        right: -16px;
        cursor: pointer;
    }

    .modal {
        overflow-y: scroll;

        max-height: 85%;
        .modal-content {

            padding: 10px;
            font-size: 1rem;
        }
    }
    .card-content {
        font-size: 1rem;
    }

    .card-title {
        font-size: 1.25rem;
        line-height: 1.25;
    }

    .sticky-contact {
        display: none;
    }

    label {
        font-size: 1rem;
    }
}

.page-template-page-cylinder-xpress {
    a {
        &:hover {
            cursor: pointer;
        }
    }
    a.btn {
        &:hover {
            color: #fff;
        }
    }

    input[type="text"] {
        color: #262626;
    }


    ul.collapsible {
        li.active {
            .collapsible-header {
                background: linear-gradient(to bottom,rgba(252,254,255,0) 0%,#f5f5f5 100%);
            }
        }
    }

    .collapsible-header, table.highlight>tbody>tr {
        transition: all 0.3 ease;

        &:hover {
            background: linear-gradient(to bottom,rgba(252,254,255,0) 0%,#f5f5f5 100%);
        }
    }

    th {
        padding-top: 0;
    }

    table.td-33 {
        th, td {
            width: 33%;
        }
    }
}
