/* Start Header black bar */
.fullscreen-bg{
    height: calc(100vh - #{$navbar-height});
    min-height: 768px;
    background-image: url(/wp-content/uploads/2017/12/swanson-alt-mobile-video-bkg768x1024.jpg);
    background-size: cover;
    background-position: top left;
    min-height: 0px;
    display: flex;
    flex-direction: column;
    @media (max-width: $breakpoint-phone) {
        height:auto;
    }
    img.img-logo {
        display: block;
        margin: initial;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding-top: 5.5%;
        max-width: 475px;
        position: relative;
        @media (min-width: $breakpoint-ipad) {
            margin-bottom: 3rem;
        }
    }
    div:nth-of-type(1){
        @media (max-width: $breakpoint-phone) {
            position: relative;
        }
    }
    #screen{
        background-image: url(img/overlay.png);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(9, 45, 115, .4);
        overflow: hidden;
    }
    div.blackbar {
        width: 100%;
        background-color: rgba(0,0,0,.65);
        // position: absolute;
        // bottom: 0;
        // margin-top: -150px;
        margin-top: auto;
        position: relative;
        padding: 22px 35px 35px 35px;
        @media (max-width: $breakpoint-phone) {
            margin-top: 0px;
            padding: 15px;
            position: relative;
        }
        #icon-contain{
            max-width: 730px;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            & > .col {
                margin-top: 2em;
                @media (min-width: 768px) {
                    margin-top: 0;
                }
            }
            .icon-bg {
                background: rgba(255,255,255,0.25);
                text-align: center;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                position: relative;
                min-height: 130px;
                border-radius: 8px;
                @media (min-width: $breakpoint-ipad) {
                    margin-top: -3rem;
                }
            }
            a {
                width: 100%;
                color: #fff;
                text-transform: uppercase;
                font-family:"industry",sans-serif!important;
                font-size: 1.5rem;
                font-weight: 600;
                position: absolute;
                left: 0;
                top: -25px;
                height: 100%;
            }
            img {
                // Below are styles for if they are SVG maps (replace existing code)
                // width: auto;
                // display: block;
                // margin: 0 auto 14px;
                // max-height: 75%;
                // filter: drop-shadow(3px 2px 6px rgba(0, 0, 0, 0.5));
                width: auto;
                display: block;
                max-height: 100%;
                margin-left: 30%;
                margin-bottom: -1em;
                @media (min-width: 600px) {
                    margin-left: 12.5%;
                }
            }
            @media (max-width: $breakpoint-phone) {
                flex-direction: column;
            }
        }
        h1,p {
            color:#fff;
            margin: 0 auto;
            text-align: center;
            font-size: 25px;
            line-height: 1.4;
        }
    }
    .scroll-down {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 120px;
    }
}
/* End Header black bar */

.home {
    .card {
        width: 100%;
        overflow: hidden;
        &:hover {
            .card-reveal {
                visibility:visible;
                opacity: 1;
                top: 0;
                ul {
                    li {
                        left: 0;
                    }
                }
            }
        }
    }

    // card overrides
    .card-title {
        text-align: center;
        display: block;
        i.fa {
            font-size: 0.8em;
            vertical-align: middle;
        }
    }
    img.activator {
        cursor: pointer;
    }
    .card-reveal {
        font-size: 16px;
        transition:.25s all ease-in-out;
        display:inherit;
        visibility:hidden;
        ul {
            li {
                position: relative;
            }
        }
    }
    .about-copy {
        font-size: 22px;
        font-weight: 300;
        font-family: 'industry',helvetica,arial,sans-serif;
        line-height: 2.1;
        margin: 2rem 0;
    }
    .read-more{
        display:block;
        max-width: 125px;
        text-align: center;
        color:#fff;
        background-color:#7ea97e;
        margin:24px 0 26px 0;
        padding:2%;
        letter-spacing: 1px;
        border-radius: 2px;
        text-transform: uppercase;
    }
    .post-date{
        display: inline-block;
        padding: 4px 9px;
        margin-left: -14px;
        background-color: #a02c0a;
        margin-top: 25px;
        color: #f1e8e6;
        font-size: 15px;
        font-weight: 800;
    }

    .marginauto{
        margin: 0 auto;
    }
    .darkgray{
        background-color:#f4eded;
        padding:3.5% 0 2% 0;
        box-shadow: 0 -23px 65px #23262b;
        position: relative;
    }
    .darkgraytop{
        background-color:#2a2d33;
        border-top:22px solid #999;
        border-bottom:29px solid #999;
        padding:2% 0 1px;
        margin: 72px 0 0;
        background-image: url('/wp-content/uploads/2017/07/hp-form-bg717.jpg');
        background-size: contain;
        box-shadow: 0 14px 50px #23262b;
        position: relative;
        z-index: 9;
    }
    .videoWrapper {
        position: relative;
        padding-bottom: 30.25%!important; /* 16:9 */
        padding-top: 25px!important;
        height: 0;
        margin-top:65px;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    textarea {
        border-radius: 7px;
    }
    input[type=submit]{
        background-color:#7aa87e;
        border: 0;
        color:#fff;
        padding:8px 2rem;
        border-radius: 0;
        text-transform: uppercase;
        font-weight:800;
    }
    .padding-5{
        padding: 0 5%;
    }
    .max-text{
        max-width: 800px;
        margin: 0 auto;
        padding: 2%;
    }
    .absolute-bg{
        background-size: cover;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 30, 80, 0.65);
        background-blend-mode: multiply;
    }
    .absolute-pad {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        padding:5%;
        h2.card-title{
            text-shadow: 1px 1px 0 #000;
            color:#fff;
            @media (min-width: 1150px) {
            font-size:34px;
            }
        }
        ul{
            li{
                text-shadow: 1px 1px 0 #000;
            @media (min-width: 1150px) {
                font-size:20px;
            }
                a{
                    color:#fff;
                }
            }
        }
    }
    img {
        max-width: 100%
    }
    .playbtn {
        width: 110px;
        height: 110px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -55px;
        margin-top: -55px;
        transition: .25s all ease-in-out;
        opacity: .65;
        &:hover {
            transform: scale(1.1);
            opacity: 1;
        }
    }
}

.max-width-1200{
    max-width: 1200px;
    input[type=text]{
        padding: 0!important;
    }
}
.hide {
    display: none;
}
.hp-contact-pad {
    background-image: url('/wp-content/uploads/2017/07/hp-form-bg717.jpg')!important;
    background-size: 100%!important;
    background-repeat: no-repeat;
    @media (max-width: $breakpoint-phone) {
        background-image: none!important;
    }
    .max-text {
        @media (max-width: $breakpoint-phone) {
            padding: 0!important;
            padding-bottom: 2em!important;
            font-size: 25px!important;
        }
    }
}
body.home {
    .contact-change {
        background-color: rgba(31, 31, 31, 0.32);
        background-color: rgba(241, 240, 240, 1);
        padding: 2% 2%!important;
        box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.09), 8px 8px 17px rgba(0, 0, 0, 0.16)
    }
}

.chrome-bg {
    background-image: url('/wp-content/uploads/2017/07/chrome-bkg-text.jpg');
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 7%;
    background-size: 100%;
}

//custom services blocks
section.hp-services{
    .row {
        margin: 0;
        padding: 0;
        background-color:#e3e3e3;
        .col {
            padding: 0;
            margin: 0;
            .hp-left-resp {
                display: table;
                height: 100px;
                width: 100%;
            }
            img {
                display:block;
            }
            .hp-service-left {
                max-width: 898px;
                float: right;
                width: 100%;
                padding: 0 5% 0 3%;

                }
            .hp-service-right {
                max-width: 898px;
                float: left;
                width: 100%;
                padding: 0 5% 0 3%;
            }
            h2 {
                font-family:'texgyreheroscn_bold',helvetica,arial,sans-serif;
            }
            ul {
                li {
                    font-family:'texgyreheroscn_bold',helvetica,arial,sans-serif;
                    a {
                        color: $swanson-blue;
                        text-decoration: none;
                        font-size: 32px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
body.home{
     video{
        display:block;
    }
}
footer{
    p.footer-phone{
        a{
            color:#fff;
            border-bottom: 1px dotted #fff;
        }
    }
}
section{
    p.change-phone{
        a{
            color:#fff;
            border-bottom: 1px dotted #fff;
        }
    }
}
.bta-parallax2 {
    @media (max-width: $breakpoint-ipad) {
        background-image: none!important;
    }
    @media (min-width: 1921px) {
        background-size: cover;
        background-position: left top!important;
    }
}

.hp-parallax-wrapper {
    padding-left: 6%;
    @media (min-width: 1100px) {
        padding-left: 34%;
    }
}