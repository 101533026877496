.card {
    i.fa {
        color: $primary-color;
    }
    h2.card-title {
        font-size: 24px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .card-action {
        a {
            color: #002c73 !important;
        }
    }

}

// video lightbox cards
.video-card, .contact-change{
    margin-top: 0;
    background-color: rgb(241, 240, 240);
    padding: 1.5% 1.5%!important;
    box-shadow: $swanson-shadow;
    .info {
        background-color: #3c3f46;
        padding: 1% 2%;
        color: #dcd8d8;
    }
}

.card-drop {
    box-shadow: $swanson-shadow;
}

.playbtn{
    width: 110px;
    height: 110px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -55px;
    margin-top: -55px;
    transition:.25s all ease-in-out;
    opacity:.65;
    &:hover{
        transform: scale(1.1);
        opacity: 1;
    }
}