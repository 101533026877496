
nav {
    z-index: 900;
    box-shadow: 0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);
    height: $navbar-height;
    //line-height: $navbar-height;
    background-color: $navbar-background-color;
    position: fixed;
    width: 100%;
    ul {
    	li {
    		a {
			    text-transform: uppercase;
    		}
    	}
    }
    .brand-logo {
    	position: relative;
		img {
	        width: auto;
	        height: 56px;
	        max-width: 100%;
	    }
	    .number {
	    	display: inline-block;
	    	vertical-align: top;
	    }
    }
    .container, .brand-logo {
    	height: $navbar-height;
    }

    .side-trigger {
    	width: 48px !important;
	    line-height: 1 !important;
	    height: 48px !important;
	    font-size: 24px;
	    text-align: center;
        border: 1px solid #9a9c9e;
        background-color: #FFF;
    	color: $primary-color;
    	border-radius: 100%;
    	margin-top: 4px;
    	display: flex;
    	align-items: center;
    	transition: 0.15s ease-in-out;

    	i {

	    	width: 48px !important;
		    line-height: 48px !important;
		    height: 48px !important;
		    font-size: 24px;
		    vertical-align: middle;
	    }
	    &:hover {
	    	color: lighten($primary-color, 10%);
    		transition: 0.15s ease-in-out;
	    }
    }

    li {
        ul.nav-drop {

            position: absolute;
			width: inherit;
			display: none;

			li.nav-drop-item {
			    float: none;
			    display: block;
			    z-index: 100;
			    min-width: 150px;
			    line-height: 2;
			    a {
		            background-color: $primary-color;
			    }
			}
        }

        &:hover  ul.nav-drop {
            display: block;
        }

        // full width dropdown sub menu
		.wide-sub-menu {
		    @extend .z-depth-4;

    		background-color: $primary-color;
			padding:0;
			display:none;
			width: 100%;
			position: absolute;
			left: 0px;
			z-index: 100;
			line-height: 2;
			padding-top: 20px;
			padding-bottom: 20px;

			.row {
			     margin-bottom: 0;
			}

			a {
			    padding-top: 20px;
			    padding-bottom: 20px;
			    text-align: center;
			}

		}

		// show sub on hover
		&:hover .wide-sub-menu {
			//display: block;
			//@extend .animated;
			//@extend .fadeInLeft !optional;
		}
    }
}

.fixed-nav-padding {
	padding-top: $navbar-height;
}

// side nav
.side-nav {
	@extend .z-depth-2;
	//background-image: url(/wp-content/uploads/2017/06/footer-bkg-1440.jpg);
	background-color: #012d76;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: 1000;
	width: 100%;
	max-width: 350px;
	padding-left: 0;
	padding-bottom: 44px!important;
	 @media (max-width: $breakpoint-phone) {
        width: 300px;
    }
    .terms-btn{
	display: block;
    background-color: #7aa87d;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    padding: 8px 28px 7px;
    &:hover{
    	background-color:#5e8565;
    }
	}
	li {
		margin: 0.25em 0;
	}
	// links
	a {
		color: #c1c8da;
		height: auto;
		text-transform: none;
		padding: 0 28px;
		line-height: 22px;
		&:not(.subheader) {
			padding-top: 0.75em;
    		padding-bottom: 0.75em;
		}
	}
	// subheader
	.subheader {
		// background-color: $primary-color;
		color: #fff;
		text-transform: uppercase;
		font-weight: 600;
		display: flex;

		i.fa {
			align-self: center;
			margin: 0 10px;
			float: none;
			height: auto;
			width: auto;
			font-size: 1em;
			color: #fff;
		}

	}

	//accordion

	.accordion-toggle {

		background-color: #153f86;

		//border-bottom: 1px solid #fff ;
	    i.fa {
	        transition: .25s all ease-in-out;
	    }

	    &:hover {
	    	cursor: pointer;
	    }
	    &:nth-of-type(1n-1){
	    	margin: 0px;
	    }

	    &.locations {
	    	& + div {
	    		li a {
		    		padding-top: 0;
	    			padding-bottom: 0;
		    	}
	    	}
	    }
	}

	.accordion-content {
	    opacity: 0;
	    visibility: hidden;
	    max-height: 0;
	    transition:.25s all ease-in;
	    background-color: rgba(0, 0, 0, 0.05);
	    //border-bottom: 1px solid #fff ;
	    padding-bottom: 10px;
	    padding-top: 10px;

	    a {
	    	padding-left: 45px;

	    	@media #{$small-and-down} {
	    		padding-left: 28px;
		    }
		    //&:nth-last-of-type(1){
		    //	margin-bottom:10px
		    //}

	    }
	    &:nth-of-type(1){
		    	li{
		    		&:nth-of-type(1){
						a{
					        padding-bottom: 1em;
					        padding-top: 0;
					    	&:before{
					    		content: url(/wp-content/uploads/2017/11/australia-wht-menu.png)!important;
					    		position: relative;
							    top: 11px;
							    left: -7px;
					    	}
						}
		    		}
		    		&:nth-of-type(2){
						a{
				    	    padding-bottom: 1em;
				    	    padding-top: 0;
					    	&:before{
					    		content: url(/wp-content/uploads/2017/11/chile-wht-menu.png)!important;
					    		position: relative;
							    top: 11px;
							    left: -7px;
					    	}
						}
		    		}
		    		&:nth-of-type(3){
						a{
				    	    padding-bottom: 1em;
				    	    padding-top: 0;
					    	&:before{
					    		content: url(/wp-content/uploads/2017/11/us-wht-menu.png)!important;
					    		position: relative;
							    top: 11px;
							    left: -7px;
					    	}
						}
		    		}
		    	}
		    }
	}
	.animation{
	    opacity: 1!important;
	    visibility: visible!important;
	    max-height: 500px!important;
	}
	.rotated {
	    -webkit-transform: rotate(-180deg);
	    -moz-transform: rotate(-180deg);
	    -o-transform: rotate(-180deg);
	    -ms-transform: rotate(-180deg);
	    transform: rotate(-180deg);
	}

	// custom menu items
	.btn-item {
		margin-top: -10px;
		margin-bottom: 10px;
		//text-align: center;
		padding: 0px 45px;
		@media #{$small-and-down} {
			padding: 0px 28px;
		}
		a {
			@extend .waves-light;
			@extend .waves-effect;
			@extend .btn;
			margin: 10px 0px !important;
			padding: 10px 35px !important;
			width: 100%;
			line-height: 1.5;
		}
	}
	@media (max-width: 600px) {
		width: 325px!important;
	}
}
// new nav styles.
nav {
	.input-field{
	float:left;
	margin-top: -3px;
	}
	li{
		form{
			input{
				margin-left: 0!important;
				border: 1px solid #2e528a!important;
				padding-left:.25rem!important;
				padding: .5rem!important;
				color: #000!important;
				font-size: 14px!important;
				width: 100%!important;
				&:focus {
					background-color: white;
					transition: 0.15s ease-in-out;
				}
			}
		}
	}
	.linkli{
	position: relative;
	overflow: hidden;
		&:nth-of-type(4){
			position: relative;
			overflow: hidden;
			a{
			&:after {
			    height: 50px;
			    width: 50px;
			    background-color: #f4eded;
			    content: '';
			    position: absolute;
			    border-radius: 50px;
			    top: 3px;
			    right: -43px;
			}

			}
	}
	a{
		color: #002c73;
		font-weight: 500;
		letter-spacing: 1px;
	&:hover{
				background-color: rgba(0, 47, 115, 0.14);
			}
	}
	}
}
.lity{
	background: rgba(2, 4, 23, 0.9)!important;
}
.sidebar-logo{
	width: 84%;
    display: block;
    margin: 18px auto;
}

@media (max-width: $breakpoint-992) {
	.hide-on-med-and-up {
		display: block !important;
	}
}

@media (min-width: #{$breakpoint-992 + 1}) {
	.hide-on-med-and-up {
		display: none !important;
	}
}

.page-template-page-australia, .parent-pageid-60{
	.accordion-content {
	    a {

		    }

	    }
	    &:nth-of-type(1){
		    	li{
		    		&:nth-of-type(1){
						a{

					    	&:before{
					    		content: ''!important;
					    		position: relative;
							    top: 0;
							    left: 0;
					    	}
						}
		    		}
		    		&:nth-of-type(2){
						a{

					    	&:before{
					    		content: ''!important;
					    		position: relative;
							    top: 0;
							    left: 0;
					    	}
						}
		    		}
		    		&:nth-of-type(3){
						a{

					    	&:before{
					    		content: ''!important;
					    		position: relative;
							    top: 0;
							    left: 0;
					    	}
						}
		    		}
		    	}
		    }
	}
