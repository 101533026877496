section.bottom-callout {
    @extend .builder-grid;
    @extend .padding-3-0;
    margin-top: 20px;
    background-color:#000c1f!important;
    .row, h3 {
        margin-bottom: 0;
    }
    .row {
        @extend .flex-center-align;
        @extend .flex-row;
    }

    p {
       margin: 1em 0 !important;
    }

    @media #{$small-and-down} {
        padding: 2em 0 !important;

        p {
            margin: 1em 0 0 0 !important;
        }
    }
}

footer.page-footer {
    border-left: 20px solid #7ea97e;
    background-image: url(/wp-content/uploads/2017/06/footer-bkg-1440.jpg);
    background-color: #002b6f;
    background-size: cover;
    background-position: center 36px;
    background-repeat: no-repeat;
    margin-top: 0px;

    @media (max-width: $breakpoint-after-ipad) {
        background-image:none;

    }

    li a:hover {
        text-decoration: underline;
    }
        .footer-rows:nth-of-type(1), .footer-rows:nth-of-type(2){
            border-right:1px solid #8b95a4;
            margin-top: 5%;
            @media (max-width: $breakpoint-after-ipad) {
                width:50%!important;
            }
        }
        .footer-rows:nth-of-type(2){
            @media (max-width: $breakpoint-after-ipad) {
                border-right:none;
            }
        }
        .footer-rows:nth-of-type(3){
            margin-top: 5%;
            @media (max-width: $breakpoint-after-ipad) {
                width:100%!important;
                background-color: rgba(0,0,0,.25);
                padding: 5%;
            }
        }
        .footer-rows:nth-of-type(4){
            margin-top: 10%;
            @media (max-width: $breakpoint-after-ipad) {
                width:100%!important;
            }
            @media (min-width: 768px) {
                margin-top: 2%;
            }
        }
        .footer-rows{
            padding:0 2% 1%;
        }
        .form-group{
            width:62%;
            float:left;
            input{
                border-radius:5px 0 0 5px;
                padding: 0 15px;
                font-size: 21px;
            }
        }
        .btn{
            height:auto;
        }

        h3{
            color:#fff;
            font-weight:600;
        }
        input[type=submit]{
            padding:.25em 16px;
        }

        .footlogo-p{
            color:#aeb2b7;
            font-weight:300;
            line-height:1.7;
            font-size:18px;
                strong{
                    color:#fff;
                    font-weight: 600;
                    letter-spacing: .5px;
                }
        }
        .col{
            ul{
                padding:0px !important;
                margin:0;
                li{
                    list-style-image:none!important;
                    a{
                        color:#fff;
                        font-size:17px;
                        letter-spacing: .5px;
                        font-weight:200;
                    }
                }
            }
        }
        .container{
                background-image: url();
                background-size: 100%;
        }
        a.fa{
            color: #7b8188;
            font-size: 28px;
            padding: 0 2px;
            text-shadow: 1px 1px 0 #00102a;
            margin-right: 10px;
        }

    .info {
        color: #aeb2b7;
        font-size: 17px;
        text-align: center;
        a {

            text-decoration: underline;
        }
    }
}
