.single-jobs {
    article.article-body {
        margin-bottom: 2rem;
        .title-summary {
            font-weight: 600;
        }
    }
}

.page-template-page-application {
    .button {
        @extend .waves-light;
        @extend .waves-effect;
        @extend .btn;
    }
    .gform_wrapper {
        //margin-top: -20px !important;
        line-height: 1;
        width: 100%;
        max-width: 100% !important;
    }
    li.gfield label{
        font-size:14px;
        font-weight:300!important;
    }
}