/*! Custom Global Styles (custom.scss) */

$global-transition: 0.25s ease-in-out;

.max-width-no {
     @media (max-width: $breakpoint-max-width) {
        display: none;
    }
}
.col {
    ul {
        padding-left: 50px;
        list-style-position: outside;
        li {
            list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='13' width='13'%3E%3Crect width='13' height='13' style='fill:%237aa87e;'%3E%3C/rect%3E%3C/svg%3E");
            padding-left: 5px;
        }
    }

    ul.check {
        li {
            list-style-image: url('./img/checkli.png')!important;
            padding-left: 5px;
        }
    }
}
// black arrow on friction welding page
.arrow-weld {
    display: none;
    transform: rotate(45deg);
    position: absolute;
    left: -28px;
    top: 50%;
    margin-top: -74.5px;
    width: 80px;
    height: 80px;
    background-color: #000;
    @media (max-width: $breakpoint-992) {
        left: 50%;
        margin-left: -40px;
        top: -22px;
        margin-top: auto;
    }
}
// friction welding specific styles
body.page-id-442 {
    .friction-list {
        min-height: 247px!important;
        margin: 20px 0;
    }
    .weld-img {
        padding: 0!important;
        position: relative;
            img {
                position: relative;
            }
    }
    .arrow-weld {
        display: block;
    }
}

// laser cladding tables
body.page-id-440 {
    table {
    border-collapse: separate;
    border-spacing: 1px;
    margin: 3% 0;
    }
}
#lc-table tr td.toprow {
    border-bottom: 2px solid #293829;
    font-weight: 800;
    text-transform: uppercase;
    @media (max-width: $breakpoint-phone) {
        font-size: 15px;
    }
}
#lc-table tr td {
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcfeff+0,f5f5f5+100&0+0,1+100 */
background: -moz-linear-gradient(top, rgba(252,254,255,0) 0%, rgba(245,245,245,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(252,254,255,0) 0%,rgba(245,245,245,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(252,254,255,0) 0%,rgba(245,245,245,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcfeff', endColorstr='#f5f5f5',GradientType=0 ); /* IE6-9 */
}

// flex row
.flex-row {
    display: flex;
    flex-wrap: wrap;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    .col {
        p, h1, h2, h3, h5, h5 {
            width: 100%;
            display: block;
            margin-top: 0px;
        }
    }
}

ul.twocol {
    &:after {
        content: '';
        clear: both;
    }
    li {
        width: 50%;
        float: left;
    }
}
.clearboth {
    clear: both;
}
// Parker Distribution
.parker-parts {
    .m4 {
        border-right: 1px solid #e3e3e3;
        padding: 2%;
    }
    .m4:nth-of-type(3) {
        border-right: none;
    }
    div img {
        margin: 0 auto;
        display: block;
        padding: 1em 0;
        max-width: 350px;
    }
}
.flex{
    display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: flex;
}

.parker-product-img {
    width: auto;
    max-height: 215px;
    display: block;
    margin: 0 auto;

    @media #{$small-and-down} {
        margin: 0;
    }
}

// image dropshadow
.img-drop {
    border: 10px solid #fff;
    box-shadow: $swanson-shadow;
}
ol {
    li {
        padding: 1.5% 0;
        font-weight: 900;
        font-size: 22px;
        span {
            font-weight: 300;
            font-size: 18px;
        }
    }

}

// gravity forms wrapper
.gform_wrapper {
    ul {
        padding-left: 0!important;
    }
    input[type=text] {
            text-indent: .7em!important;
    }
    textarea {
        text-indent:.5em!important;
    }

}
// validation error
.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
    background-color: #FFF;
    border: none;
}
// contact form
.gform_wrapper .gfield_checkbox li label, .gform_wrapper .gfield_radio li label {
    padding-left: 24px !important;
    margin-left: 0 !important;
}
.gform_wrapper .gfield_html {
    font-size: 15px;

    a {
        text-decoration: underline;
    }
}

// padding and margins
.padding-3-0 {
    padding: 3% 0!important;
    @media (max-width: $breakpoint-phone) {
        padding: 7% 0;
    }
}
.padding-0-0-3 {
    padding: 0 0 3% 0;
}
.light-gray-nopad {
    padding: 0;
    background-color:#E3E3E3;
    margin-top: 8%;
    margin-bottom: -4%;
        img {
            display: block;
            margin: -10% auto 0;
        }
}

.bg-blue-vector {
    background-repeat: no-repeat;
    background-position: top right;
    .card {
            box-shadow: 5px 4px 15px #888686;
    }
}

.parallax-container {
    height: auto;
    &.blog-header {
        .country-flag {
            position: absolute;
            width: 100%;
            bottom: 0px;
            .description {
                .meta {
                    @media (max-width: 768px) {
                        margin-bottom: 0;
                    }
                    img {
                        @media (max-width: 768px) {
                            margin-bottom: 0;
                        }
                    }
                }
                h1 {
                    @media (max-width: 768px) {
                        padding-top: 0!important;
                    }
                    padding-top: 20px!important;
                }
            }
        }
        .content-container {
            position: absolute;
            width: 100%;
            bottom: 0;
            background-color: rgba(3, 9, 25, 0.75);
            color: #fff;
            @media (max-width: 768px) {
                top: 0;
            }
        }

    }
}

.mobile-darken.darken-left .parallax-container, .mobile-darken.darken-left.parallax-container {
    @media (min-width: $breakpoint-992) {
        & > .parallax::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: -moz-linear-gradient(-90deg, rgba(23,23,65,0) 30%, rgba(23,23,65,0.7) 65%);
            background: -webkit-linear-gradient(-90deg, rgba(23,23,65,0) 30%, rgba(23,23,65,0.7) 65%);
            background: linear-gradient(-90deg, rgba(23,23,65,0) 30%, rgba(23,23,65,0.7) 65%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171741",endColorstr="#171741",GradientType=1);
        }
    }
}

.mobile-darken.darken-left.us-worker-bg::after {
    @media (min-width: $breakpoint-992) {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: -moz-linear-gradient(-90deg, rgba(23,23,65,0) 40%, rgba(23,23,65,0.7) 65%);
        background: -webkit-linear-gradient(-90deg, rgba(23,23,65,0) 40%, rgba(23,23,65,0.7) 65%);
        background: linear-gradient(-90deg, rgba(23,23,65,0) 40%, rgba(23,23,65,0.7) 65%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171741",endColorstr="#171741",GradientType=1);
    }
}

.mobile-darken.darken-right .parallax-container, .mobile-darken.darken-right.parallax-container {
    @media (min-width: $breakpoint-992) {
        & > .parallax::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: -moz-linear-gradient(90deg, rgba(23,23,65,0) 30%, rgba(23,23,65,0.7) 65%);
            background: -webkit-linear-gradient(90deg, rgba(23,23,65,0) 30%, rgba(23,23,65,0.7) 65%);
            background: linear-gradient(90deg, rgba(23,23,65,0) 30%, rgba(23,23,65,0.7) 65%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171741",endColorstr="#171741",GradientType=1);
        }
    }
}

.countries {
    li {
        @media (max-width: $breakpoint-ipad) {
            text-align:center;
        }
        a {
            line-height: 1.1;
        }
    }
    .country-icons-footer {
        max-width: 30px!important;
        position: relative;
        top: 6px;
        margin-right: 5px;

        @media (max-width: $breakpoint-ipad) {
            display: block;
            margin: 7px auto;
        }
    }
}
// button mouseover
.btn-hover, .gform_button, .read-more {
    transition: $global-transition;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    color: #fff;
    box-shadow: 2px 2px 10px rgba(0,0,0,0);
    &:hover {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        transform: perspective(1px) scale(1.05);
        color: #fff!important;
    }
}

a.btn-cta {
    @extend .btn;
    height: auto;
    background-color: #B9592B;
    color: #F5E1D8;
    &:hover{
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
        transform: perspective(1px) scale(1.05);
        color: #fff!important;
        background-color: #7B3915;
    }
}

.btn-img {
    transition:$global-transition;
    box-shadow: 2px 2px 10px #858a96;

    &:hover{
        box-shadow: 2px 2px 10px #000;
    }
}

.excerpt-page{
    font-size: 16px;
    color: #86898c;
}
body.search{
    .card{
        box-shadow: 1px 1px 1px rgba(27, 43, 80, 0.58), 4px 4px 13px rgba(22, 37, 74, 0.42);
    }
}

.text-center {
    text-align: center;
}

.blue-text {
    color: #14426A !important;
}

// embed resposively
.embed-container {
    @extend .img-drop;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

}
.embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

ul.collection li {
    list-style-type: none;
    padding-left: 0;
}

// sticky contact
.sticky-contact {
    position: fixed;
    right: -2px;
    top: 50%;
    width: 65px;
    margin-top: -119px;
    z-index: 9;
    transition: 0.15s ease-in-out;
    opacity: 0.8;
    @media (max-width: $breakpoint-ipad) {
        position: fixed;
        left: 17%;
        bottom: 0;
        z-index: 9;
        transform: rotate(90deg);
        display: none!important;
    }
    svg {
        height: 250px;
        filter: drop-shadow(1px 3px 6px rgba(0, 0, 0, 0.25));
    }
    &:hover {
        transition: 0.15s ease-in-out;
        right: 0;
        opacity: 1;
    }
    & + .fixed-action-btn .fa-chevron-up {
        animation: bounce-updown 1.5s ease-in-out infinite;
    }
}

@keyframes bounce-updown {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-4px);
    }
    100% {
        transform: translateY(0);
    }
}

.sticky-contact-btm{
    display:none;
    background-color:#b94b29;
    color:#fff;

   @media (max-width: $breakpoint-after-ipad) {
        display: block;
        position: fixed;
        width: 100%;
        bottom: 0;
        text-align: center;
        padding: 4%;
        z-index: 99;
    }
}
//shortcode blogs styles
.shortcode-blogs{
    .row {
        display: flex;
        flex-wrap: wrap;
        display: -ms-flexbox;
        ms-flex-wrap: wrap;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }

    .blogwrap{
        width: 31% !important;
        margin: 0.75%!important;
        box-shadow: 1px 1px 1px #aaaaab, 2px 2px 10px rgba(154, 154, 154, 0.52);
        border-radius: 2px;
        background-color: #ffffff;
        padding: 0;
        transition:$global-transition;

         @media (max-width: $breakpoint-after-ipad) {
             width: 100%!important;
             margin-top: 1em!important;
         }

        &:hover{
            background-color: #585858;
            h4, a {
                color: #fff;
            }
            p{
                color: #fff!important;
            }
            .read-more{
                color: #fff!important;
            }

            .blogwrap-col, .text-wrap {
                transform: translateY(-10px);
            }
        }

        &:hover .blogwrap-col{
            border: 2px solid #585858;
        }
        .blogwrap-col{
            background-size: cover;
            border: 2px solid #223667;
            transition: $global-transition;
            background-position: top left;
            background-repeat: no-repeat;
            .blog-date{
                display: block;
                height: 190px;
                position: relative;
                .post-date {
                    display: inline-block;
                    padding: 18px 9px;
                    margin-left: 0;
                    background-color: rgb(232, 79, 31);
                    margin-top: 14px;
                    color: #ffffff;
                    font-size: 23px;
                    font-weight: 800;
                    width: 75px;
                    height: 75px;
                    border-radius: 100%;
                    text-align: center;
                    line-height: 1;
                    position: absolute;
                    bottom: -25px;
                    /* border: 3px solid #012d76; */
                    font-family: industry;
                    text-shadow: 1px 1px 1px #6b2008;
                    font-weight: 300;
                }
            }
        }
        .text-wrap {
            color: #333;
            padding: 3% 8%;
            transition:$global-transition;
            h4 {
                padding-top: 20px;
                text-shadow: 1px 1px 0 #0e1935;
                transition: $global-transition;
                line-height: 1.2!important;
                display: block;
                a {
                    text-shadow: none;
                    font-weight: 800;
                    transition: $global-transition;
                }
            }
            p {
                transition: $global-transition;
            }
            .read-more {
               color: #fff;
            }
        }
    }
}
strong {
    font-weight: 800;
}

//united states styles
.us-worker-bg {
    background-position: top right;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    .us-worker-col {
        color: #fff;
        padding: 6% 0 12%;
        text-shadow: 1px 1px 1px #000;
        z-index: 2;
        position: relative;
        p {
            font-size: 30px;
            line-height: 1.3;
        }
        ul {
            li {
                font-size: 22px;
            }
        }
    }
}
.no-margin-gray {
    background-color: #e3e3e3;
}
.no-margin {
    margin: 0!important;
}
.scale-p {
    p {
        font-size: 25px;
        padding: 8% 2%;
        color: #09436A;
        @media (max-width: $breakpoint-phone) {
        padding: 2%;
        }
    }
}
.no-padding {
    padding: 0!important;
}

// material collapsible
ul.collapsible {
    padding-left: 0 !important;
    li {
        list-style-image: none;
        padding: 0 !important;
        .collapsible-header {
            font-family: "quicksand", helvetica, arial, sans-serif;
            text-transform: uppercase;
            font-size: 14px;
        }
        .collapsible-body {
            padding: 10px;
        }
    }
}

// location styles

.locations-list {
    p {
        margin-top: 0;
    }
    .list-row .col {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.locationheadings {
    font-weight: 700;
    background-color: rgba(161, 164, 169, 0.43);
    color: #fff;
    padding: 1% 2% .75%;
    text-shadow: 1px 1px 1px #6d9470;
    margin: 4% -1% 2% -1%;
    font-size: 38px;
    letter-spacing: -1px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#79a67d+0,79a67d+100&1+16,0+74 */
    background: -moz-linear-gradient(left, rgba(121,166,125,1) 0%, rgba(121,166,125,1) 16%, rgba(121,166,125,0) 74%, rgba(121,166,125,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(121,166,125,1) 0%,rgba(121,166,125,1) 16%,rgba(121,166,125,0) 74%,rgba(121,166,125,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(121,166,125,1) 0%,rgba(121,166,125,1) 16%,rgba(121,166,125,0) 74%,rgba(121,166,125,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#79a67d', endColorstr='#0079a67d',GradientType=1 ); /* IE6-9 */
    @media (max-width: $breakpoint-phone) {
    background-color:rgb(121,166,125)!important;
    text-align: center;
    padding: 4% 0;
    }
}

.location-services {
    box-sizing: border-box;
    margin: 1.5% 0 2.5%;
    margin-left: 0!important;
    .feature-img {
        background-size: cover;
        background-position: top left;
        height: 176px;
        width: 100%;
        background-repeat: no-repeat;
    }
    .padding {
        padding-left: 7%!important;
        padding-right: 7%!important;
        padding-top: 5% !important;
        transition: $global-transition;
        padding-bottom: 4%!important;
        padding: 3% 5%;
        h3 {
            font-weight: 600;
        }
    }
    .location-services-span {
        height: 100%;
        display: block;
        margin: 0 3%;
        background-color: rgba(255, 255, 255, 0.41);
        transition: $global-transition;
        overflow: hidden;
        padding-bottom: 4%;
        border: 2px solid #f5f5f5;
        position: relative;
        margin-bottom: 40px;
        @media (min-width: 768px) {
            overflow: inherit;
        }
        .feature-img, img, h3, a {
            transition: $global-transition;
        }
        a.btn {
            display: block;
            line-height: 1.2em;
            margin: 1.5em;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transition: $global-transition;
            &:hover {
                transform: scale(1.025);
                transition: $global-transition;
            }
        }
        ul.links {
            margin-top: 10px;
            padding-left: 35px;
            li a {
                position: relative;
                margin: 0.4em 0;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        ul:not(.links) {
            padding-left: 25px;
        }

        .au-distribution.links {
            li {
                margin-bottom: 0.5em;
            }
            a {
                .stauff {
                    path {
                        fill: #000;
                        transition: .25s ease-in-out;
                    }
                }
                svg {
                    max-height: 20px;
                    width: auto;
                    max-width: 80px;
                    height: auto;
                }
            }
        }
        .parentcat {
            font-weight: 300;
            font-family: 'industry',helvatica,arial,sans-serif;
            color: #989696;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 3px;
        }
        h3 {
            font-weight: 600;
            letter-spacing: -1px;
            transition: $global-transition;
            margin: 0px;
            line-height: 1.05;
            margin-bottom: 0.25em;
        }
        &:hover {
            background-color: #585858;
            border: 2px solid white;
            h3 {
                color:#fff;
                a {
                    color: #fff;
                    transition: $global-transition;
                    &:hover {
                        opacity: 0.9;
                        transition: $global-transition;
                    }
                }
            }
            .padding {
                transition: $global-transition;
                transform: translateY(-10px);
            }
            .feature-img {
                transform: translateY(-10px);
            }
            ul.links {
                li a {
                    color: #fff !important;
                }
            }
            ul li, p {
                color: white!important;
            }
            .parentcat {
                color: white;
            }
            .au-distribution.links {
                svg {
                    path {
                        fill: #fff;
                        transition: .25s ease-in-out;
                    }
                }
            }
            .all-manufacturers {
                border-bottom-color: #fff;
                transition: $global-transition;
                text-decoration: none;
            }
        }
    }
}

// page specific overrides
body.page-id-11 {
    .feature-img {
        background-size: cover;
        background-position: top left;
        height: 215px;
        width: 100%;
        background-repeat: no-repeat;
    }
}

// america page styles

.us-header-text{
    max-width: 1060px;
    margin: 32px auto;
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: 800;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    @media (max-width: $breakpoint-phone) {
        font-weight: 500;
        padding: 0 1em;
    }
}
.us-btm-bg {
    background-image: url('/wp-content/uploads/2017/07/aea-bkg1.jpg');
    background-size: 100%;
    margin-top: 4%;
    .aea-right-bg {
        background-image: url('/wp-content/uploads/2017/07/aea-elipses-images.png');
        background-size: 100%;
        margin-top: -12%;
        padding-bottom: 12%;
        background-repeat: no-repeat;
        .swanson-logo{
            padding-top: 86%;
            max-width: 100%;
            width: 400px;
            margin: 0 auto;
            display: block;
        }
    }
    .aea-logo {
        max-width: 100%!important;
        width: 300px;
        width: 278px;
        padding-bottom: 5%;
    }
}

.us-btm-bg2 {
    background-image: url('/wp-content/uploads/2017/08/aea-bkg1-new.jpg');
    background-size: cover;
    margin-top: 2%;
        background-position: top right;
    .swanson-logo {
        position: absolute;
        bottom: 66px;
        right: 12%;
    }
    .no-margin {
        position: relative;
    }
}

.parallax-text {
    color: #fff;
    text-shadow: 1px 1px 0 #000;
    font-size: 30px;
    margin-top: 0;
    line-height: 1.3;
}
.btn {
    height: auto;
    line-height: 1.33;
    padding: 8px 2rem;
    font-weight: 500 !important;
    text-shadow: 1px 1px 0 #5c825c;

    &:hover {
        color: #fff;
    }
}
.chile-btm-bg {
    background-image: url('/wp-content/uploads/2017/08/1920-grid-bkg1.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
}

// TODO: change this from targeting page id and nth-type
body.page-id-62 .parallax-container:nth-of-type(2) .leftparallax, body.page-id-448 .parallax-container:nth-of-type(2) .leftparallax {
    display: none;
}

// utility classes
.boxshadow {
    box-shadow: 0 0 35px #000;
    position: relative;
    z-index: 9;
}
.icons {
    -webkit-filter: drop-shadow(5px 5px 7px rgba(0, 0, 0, 0.5));
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    max-width: 120px !important;
}
.no-overflow{
        overflow: hidden;
}
.flex-center-align{
    align-items: center;
    flex-wrap: wrap;
}
.padding-3-0-0{
    padding-top: 3%!important;
}
.padding-0-0-3{
    padding-bottom: 3%;
}
.overflow-hidden{
    overflow: hidden;
}
.overflow-hidden-mobile{
    overflow: hidden;
    img {
        max-width: 100%;
        margin-bottom: -5%;
        @media (max-width: $breakpoint-phone) {
        margin-bottom: -6%;
        }
    }
    @media (max-width: $breakpoint-after-ipad) {

    }
}

.overflow-x-scroll {
    @media (max-width: $breakpoint-after-ipad) {
    overflow-x: scroll;
    }
}

// flex field grid builder styles
.builder-grid {

    // add padding on second half width column
    @media #{$medium-and-up} {
        .col.padding-left {
            padding-left: 20px;
        }
    }
}

//custom manufacturing

.right-bg-man{
    background-image: url('/wp-content/uploads/2017/08/ind-hydraulics-right.jpg');
    background-size: cover;
    color:#fff;
    background-repeat: no-repeat;
}
.flex-wrap{
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    flex-wrap: wrap;
}
.green-bg-btm{
    background-color:#7AA87E;
}

.iconborder{
    padding: 1% 8% 0;
    font-size: 25px;
    div{
        border-right: 1px solid #636363;
        text-align: center;
        @media (max-width: $breakpoint-phone) {
            border-right: none;
            border-bottom: 1px solid #636363;
                padding: 25px 0!important;
        }
        img {
            padding: 6% 0;
        }
    }
    div:nth-of-type(4){
        border-right: none;
        @media (max-width: $breakpoint-phone) {
            border-right: none;
            border-bottom: none;
        }
    }
}

.bluebg-lc{
    background-image: url('/wp-content/uploads/2017/08/960-blue-pattern-background1.jpg');
    background-size: cover;
    padding: 3% 5% 5%!important;
    line-height: 1.45;
}
.cylbg-tc {
    background-image: url('/wp-content/uploads/2017/08/crusher-cylinder-finished2.jpg');
    background-size: cover;
    min-height: 400px!important;
    width: 100%;
}


.mobile-darken {
    @media (max-width: $breakpoint-992) {
        background-color: rgba(0, 29, 76, 0.72);
        background-blend-mode: multiply;
        color: #fff;
    }
}
.large-career {
    @media (max-width: $breakpoint-phone) {
        display: none;
    }
}
.small-career {
    display: none;
    margin: 0 auto;
    max-width: 100%;
    @media (max-width: $breakpoint-phone) {
        display: block;
    }

}
.right-flex-padd {
    padding: 10% 5%;
    background-size: cover;
    max-width: 800px;
    @media (max-width: $breakpoint-phone) {
        p {
            span {
                font-size: 23px!important;
            }

        }

    }
}
.left-bg-man{
    background-image: url('/wp-content/uploads/2017/08/ind-hydraulics-left.jpg');
    background-size: cover;
    @media (max-width: $breakpoint-992) {
        padding: 25% 0!important;
    }
    @media (max-width: $breakpoint-phone) {
        width: 100%;
    }
}

#btm-cyl-man-img {
    max-width: 100%;
    top: -522px;
    position: relative;
    margin-bottom: -510px;
    @media (max-width: $breakpoint-992) {
        margin-bottom: 0;
        top: 0;
    }
}

// friction welding
.fw-btm-bg {
    background-image: url('/wp-content/uploads/2017/10/friction-welding-parallax1a-flipped-1.jpg');
    background-size: cover;
    background-position: center;
}
.img-right {
    float: right;
    &::after {
        clear: both;
    }
}
.floatleft {
    float: left;
}
.floatright {
    float: right;

}


// laser cladding
.img-float {
    img {
    float: left;
    margin: 1%;
    width: 31.3%;
    }

}
.blue-bg {
    background: rgb(3,78,129);
    background: -moz-linear-gradient(300deg, rgba(3,78,129,1) 0%, rgba(9,67,106,1) 100%);
    background: -webkit-linear-gradient(300deg, rgba(3,78,129,1) 0%, rgba(9,67,106,1) 100%);
    background: linear-gradient(300deg, rgba(3,78,129,1) 0%, rgba(9,67,106,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#034e81",endColorstr="#09436a",GradientType=1);
    margin-top: 110px;

    .lc-icons-blue {
        max-width: 800px;
        margin: 50px auto 0;
        text-align: center;
        img {
            margin: 2%;
        }
    }
}
body.page-id-404, body.page-id-419, body.page-id-1778 {
    .blue-bg {
        margin-top: 20px;
         h2 {
            color:#fff!important;
            text-align: center;
            margin: 35px 15px;
        }
    }
}
.img-125{
    width: 125px!important;
    @media (max-width: $breakpoint-phone) {
        width: 75px!important;
    }
}

.no-new-margin{
    @media (max-width: $breakpoint-992) {margin-top:0!important;}
}
.displaynone {
    display: none;
}

#map {
    height: 500px;
    @media (max-width: $breakpoint-phone) {
        display: none;
    }
}

// Parker distibution page
.parker-parts div.col:nth-of-type(odd) {
    background-color: #f5f5f5;
}
.blue-text-heading {
    color: #14426A;
    font-size: 25px;
}
.text-small-gray{
    color: #95989A;
    font-size: 15px;
}
.parker-orange{
    background-color: #F09234;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e66a20+0,e88322+50,e89f34+100 */
    background: #e66a20; /* Old browsers */
    background: -moz-linear-gradient(top, #e66a20 0%, #e88322 50%, #e89f34 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e66a20 0%,#e88322 50%,#e89f34 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e66a20 0%,#e88322 50%,#e89f34 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e66a20', endColorstr='#e89f34',GradientType=0 ); /* IE6-9 */
}
.parker-btm-img {
    margin-top: -68px;
     @media (max-width: $breakpoint-phone) {
        margin-top: 0;
    }
}
.no-btm-padd{
    padding-bottom:0!important;
}
input[type=text], textarea {
    border: 1px solid #7EA97D;
    color:#444;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    padding: 0 2%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}
textarea {
    transition:$global-transition;
}
input[type=text]:focus, textarea:focus {
    border: 1px solid #e84f20;
     border-bottom: 1px solid #e84f20!important;
    color:#fff;
    letter-spacing: 1.5px;
}
.input-field label {
    left: 15px;
}
ul#side-menu {
    input[type=text] {
        width: 85%;
    }
}
.sidebar {
    input[type=text] {
     width: 85%;
    }
}
.padding-right {
    padding-right: 4%!important;
}

//deron
.alt-gray {
    padding: 3% 0% 2%;
    &:nth-of-type(1) {
        padding: 0;
    }
    .deron-product-pad {
        padding: 0 6%;

        a.btn {
            margin-top: 20px;
        }
    }

    &:nth-of-type(even) {
        background-color: #f5f5f5;
    }
    .deron-mining-link{
        color: #222;
        border-bottom: 1px solid #d0cece;
        font-weight: 600;
    }
    table {
        hyphens: auto;
        tr {
            td {
                padding: 5px;
                &:nth-child(3) {
                    background-color: #e9f2ff;
                }
            }
        }
    }
    .row {
        max-width: 95%;
        margin: 0 auto;
    }
}

.product-download {
    position: relative;
    z-index: 99;
    padding: 2%;
    display: inline-block;
    border: 1px solid #a3a4a6;
    padding: 5% 6% 2%;
    background-color:#fff;
}
ol.deron {
   li {
        font-weight: 300!important;
    }
 }

// contact form and job application
.section-full-contact, .page-template-page-application {
    .gform_wrapper {
        margin-top: 0px !important;
        label {
            color: #222;
        }
        select {
            @extend .browser-default;
            border: 1px solid #7ea97d;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            margin-bottom: 20px;
        }
        input[type="text"], textarea {
            &:focus {
                color: #444 !important;
            }
        }
        input[type="submit"] {
            @extend .btn;
        }
        .ginput_container_radio {
            label {
                padding-left: 35px !important;
                padding-top: 2px !important;
            }
        }
    }
}

.page-contact-form .gform_body .ginput_container {
    input[type="text"], textarea {
        color: white!important;
    }
}

.dark-gray-bg {
    @extend .padding-3-0;
    background-color: #95989A;
    color:#fff;
    .white-bar {
        width: 40px;
        height: 336px;
        float: left;
        background-color: #fff;
        margin-right: 6%;
        position: relative;
        top: -44px;
        @media #{$small-and-down} {
            top: 0;
        }
    }
}

//commercial distribution
.light-gray-bg-dist {
    background-color: #e3e3e3;
    padding: 2% 0;
}
.lighter-gray-bg-dist {
    background-color: #f5f5f5;
    padding: 6% 0;
}
.gray-margin {
    margin: 0 0 3%;
}
.padding-2-0 {
    padding: 2% 0!important;
}
.fletcher-img2 {
    margin-top: -100px;
    @media (max-width: $breakpoint-992) {
        margin-top: 0;
    }

}
.mining-prod-a {
    color: #222;
    border-bottom: 1px solid #d0cece;
    font-weight: 600;
    padding-top: 18px;
    display: inline-block;
}
.kamat-bg, .kamat-setup-bg {
    background-repeat: no-repeat;
    background-position: right;
    background-size: 53%;
    @media (max-width: $breakpoint-992) {
        background-position: 100% 83%;
        background-image: none!important;
        img.mobile-yes {
            margin-bottom: 1em;
        }
    }
}

.kamat-bg {
    background-image: url('/wp-content/uploads/2017/11/kamat-pump-station1016.jpg');
}

.kamat-setup-bg {
    background-image: url('/wp-content/uploads/2019/05/kamat-setup.png');
}

.mobile-yes {
    display: none;
    @media (max-width: $breakpoint-992) {
        display: block;
        max-width: 85%!important;
        margin: 0 auto;
    }
}
.kamat-white-text {
    color: #fff;
    font-size: 23px;
    padding: 0 2%!important;
}
.wide-img-left {
    max-width: none!important;
    float: right;
    width: 115%;
    @media (max-width: $breakpoint-992) {
        max-width: 85%!important;
        float: none;
        margin: 0 auto;
        display: block;
    }
}
span.big-gray {
    font-size: 23px;
    font-weight: 600;
}
h2 strong, h3 strong, h4 strong {
    font-weight: 600;
}
.padding-left{
    padding-left: 5%!important;
}
.seebach-img {
       max-width: 968px!important;
    margin: -70px auto -50px;
    display: block;
    width: 100%;
    @media (max-width: $breakpoint-phone) {
        margin: 0 auto;
    }
}
.padding-top{
    padding-top: 114px!important;
    @media (max-width: $breakpoint-992) {
        padding-top: 0!important;
    }
}
.product-download-img {
    max-width: 240px!important;
    margin-top: 20px;
}
.download-btn-product{
    margin: 20px auto;
    background-color: #000!important;
    width: 100%;
    max-width: 185px;
    border: 2px solid #fff;
    @media (max-width: $breakpoint-phone) {
     max-width: 100%;
    }
}
.imgfloat1{
    width: 57%;
    float: left;
    @media (max-width: $breakpoint-phone) {
        width: 100%;
        float: none;
    }
}
.imgfloat2{
    width: 43%;
    float: left;
    @media (max-width: $breakpoint-phone) {
        width: 100%;
        float: none;
    }
}
.shadow-btm {
    box-shadow: 2px 2px 13px #b7b7b7;
}
.jump-to-p{
    margin: .5% 0;
    a.jump-to {
        display: inline-block;
        background-color: #7aa87d;
        color: #fff;
        margin: .75% .75%;
        padding: .5% 1.5%;
        font-weight: 600;
    }
}
* {
    outline: none
}

//oem service center
.oem-grid{
    padding: 0!important;
    .border{
        margin: 1% 1.5%;
        border: 1px solid #333;
        height: 99%;
        .gray-bg {
            background-color: #F5F5F5;
            padding: 6%;
            min-height: 426px;
            img.logotop {
                max-width: 250px;
            }
            img {
                display: block;
                margin: 0 auto;
                max-height: 250px;
                width: auto;
            }
        }
        .white{
            padding: 6%;
        }
    }
}
#longwall-number{
    text-align: center;
    font-size: 130px;
    font-weight: 900;
    color: #fff;
    position: relative;
    top: 63px;
    @media (max-width: $breakpoint-phone) {
        font-size: 72px;
        top: 30px;
    }
}
.longwall-p{
        background-color: rgba(0,0,0,.7);
        padding: 4% 9%;
        font-size: 30px;
        margin: 0;
        font-weight: 500;
        text-align: center;
        @media (max-width: $breakpoint-phone) {
            font-size: 20px;
            padding: 4%;
        }
    }
section.neg-marg {
    margin-top: -4%;
    background-color:#f5f5f5;
    padding-top: 145px;
    @media (max-width: $breakpoint-992) {
            max-width: 100%;
            margin-top: 0px;
            padding-top: 6%;
        }
    .longwall-repair-img {
        max-width: 135%;
        margin-top: -165px;
        @media (max-width: 1220px) {
            max-width: 100%;
            margin-top: 0px;
        }
    }
}
.environmental-box {
    padding: 6% 3%;
    border: 10px solid #fff;
    box-shadow: $swanson-green-shadow;
    h4 {
        color: $secondary-color;
        font-size: 36px;
        line-height: 1.1;
    }
    ul {
        padding-left: 35px!important;
        li {
            color: $swanson-blue;
            font-weight: 600;
            list-style-image: url('/wp-content/uploads/2017/11/checkli.png')!important;
            padding-left: 5px;
        }
    }
    .ipad-center-icon {
        max-width: 100px!important;
    }
}
.ipad-center-icon {
    max-width: 125px!important;
    display: block;
    margin: 0 auto;
}
.availability{
    padding: 4%;
    background-color: #ffffff;
    border: 1px solid #a3a4a6;
    margin-top: 38px;
    .small-flag {
        img {
            max-width: 35px;
        }
    }
    strong {
        font-weight: 600;
        color: #5d615d;
    }
}
strong {
        font-weight: 600;
}
.iconsthreewide {
    width: 33%;
    float: left;
    margin-bottom: 25px;
}
.orangebg {
    background-color:#F09234;
}
.padding-5 {
    padding: 5%;
}
.greentext {
    color: #7aa87d;
    margin-top: 0;
    margin-bottom: 28px;
}

//flex order change
.flex-item:nth-of-type(1) {
    @media (max-width: $breakpoint-phone) {
        order: 2;
    }
}
.body-card {
    margin: 0.75% 0!important;
    box-shadow: 1px 1px 1px #aaaaab, 2px 2px 10px rgba(154, 154, 154, 0.52);
    border-radius: 2px;
    background-color: #ffffff;
    transition: $global-transition;
}
.best-practice-card {
    h4, p {
        margin: 0 !important;
    }
    h4 {
        font-size: 22px;
        font-weight: 600;
    }
    p {
        font-size: 20px;
    }
}

.video-gray{
    background-color: #3c3f46;
    padding: 2% 4% 4%;
    color: #dcd8d8;
    min-height: 110px;
    small{
        color: #9c9c9c;
    }
}
.h4-spacing {
    h4 {
        margin-bottom: -13px;
        padding-bottom: 0;
    }
    p {
        margin-top: 0.85em!important;
    }
}
.copyright {
        margin-top: 60px;
        color: #818892;
        font-size: 15px;
        letter-spacing: .5px;
    a {
        color: #818892!important
    }
}

.fw-btm {
    div {
     @media (max-width: $breakpoint-phone) {
        text-align: left!important;
        border-right: none!important;
        }
    }
    img {
        @media (max-width: $breakpoint-phone) {
        float: none;
        }
    }
}

ul li {
    list-style-type: none;
}

.center-mobile {
    @media (max-width: $breakpoint-phone) {
        text-align: center!important;
        margin: 0 auto;
        display: block;
        }
        .product-download-img {
            @media (max-width: $breakpoint-phone) {
            margin-left: 42px;
            max-width: 250px!important;
            }
        }
        p {
            .product-download-img {
            @media (max-width: $breakpoint-phone) {
            margin-left: 42px;
            max-width: 250px!important;
            }
            }
        }
}
.h3-100 {
    font-weight: 800; text-align: center; margin-top: -6px; margin-bottom: 50px;
    @media (max-width: $breakpoint-phone) {
        margin-bottom: 0;
    }
}
.location-stack {
    .container {
        .row {
            .col {
                p {
                    &:nth-of-type(1) {
                        @media (max-width: $breakpoint-phone) {
                            margin-top: 0px;
                        }
                    }
                }
                ul {
                    margin: 0;
                }
            }
        }
    }
}
.force-align-left {
     @media (max-width: $breakpoint-phone) {
        text-align: left!important;
     }
}
.mobile-no {
     @media (max-width: $breakpoint-phone) {
      display: none!important;
     }
}
.service-repair-80 {
    width: 80%;
    margin: 0 auto;
    @media (max-width: $breakpoint-phone) {
        width: 100%;
    }
    h3 {
        @media (max-width: $breakpoint-phone) {
            margin: 7% 0;
        }
    }
}
.terms {
    color: #7aa87e;
    font-size: 19px;
    margin: 8px 0 0;
    display: block;
    &:hover {
        color: lighten(#7aa87e, 5%);
    }
    @media (max-width: $breakpoint-phone) {
        font-size: 17px;
    }
}
.aea-right-pad {
    padding-right: 20%;
    padding-bottom: 4%;
    @media (max-width: $breakpoint-after-ipad) {
        padding-right: 0;
    }
}
.h3-diamond {
    @media (max-width: $breakpoint-phone) {
        margin-top: 7%;
    }
}
.green-left {
    max-width: 700px;
    font-size: 32px;
    line-height: 1.2;
    border-left: 24px solid #7aa87d;
    padding-left: 24px;
    margin: 3% auto 0;
    @media (max-width: $breakpoint-phone) {
        font-size: 24px;
        margin: 7% auto 7%;
    }
}
.retriever {
    position: relative; top: 60px; margin-top: -92px;
    @media (max-width: $breakpoint-ipad) {
        top: 0;
        margin-top: 0;
    }
}
.careers-ad {
    width: 100%;
    margin: 0 auto;
    display: block;
    max-width: 1420px;
    float: none!important;
    border: 1px solid white!important;
}
.gold-color{
    background-color: #afa856;
}
.padding-5-mobile{
    @media (max-width: $breakpoint-phone) {
     padding: 0 5% 5%;
    }
}
// white text
.white-text {
    h2, span, p {
        color: #FFF !important;
    }
    h2 {
        line-height: 1.1;
    }
    &.right-parallax-2 {
        @media (min-width: 992px) {
            padding: 8% 0 0;
        }
    }
}
// australia and chile
.page-template-page-australia, .page-template-page-chile {
    .us-header-text {
        color: #fff !important;
        text-shadow: 1px 1px 1px #000;
    }
}

// Styles from Tyler

.name_first, .name_last {
    input {
        margin-bottom: 0.2em!important;
    }
}

.red, .green, .yellow {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 8px;
    & + p {
        margin-bottom: 0em!important;
        font-size: 18px;
        margin-top: 5px;
    }
}

.red {
    background-color: #B9242A!important;
}

.green {
    background-color: #2A813C!important;
}

.yellow {
    background-color: #D18917!important;
}

.page-template-page-gallery .contact-change {
    padding: 0!important;
}

.parallax img {
    max-width: inherit!important;
}

section.bottom-callout {
    margin-top: 0;
    z-index: 500;
    position: relative;
}

.header-content-wrap .description h1.center-mobile {
    margin-top: 10px;
    margin-bottom: 15px;
    @media (min-width: 768px) {
        margin-top: 30px;
        margin-bottom: 0;
    }
}

#main-menu, .brand-logo .number {
    a {
        font-weight: 400;
    }
    @media (max-width: 1145px) {
        display: none!important;
    }
}

p:empty {
  height: 0;
  margin: 0;
  padding: 0;
}

.locations-section {
    padding: 3% 0!important;
    .col {
        & > p {
            margin: 0 0!important;
            & > img {
                padding-right: 0;
                @media (min-width: 768px) {
                    padding-right: 14px;
                }
            }
        }
        h2 {
            text-align: center;
            padding-top: 10px;
            @media (min-width: 768px) {
                text-align: left;
                padding-top: 25px;
                padding-left: 10px;
                display: inline-block;
            }
        }
    }
}

.location-stack {
    padding: 8% 0;
    @media (min-width: 768px) {
        padding: 3% 0 3%;
    }
    h4 {
        color: #14426a;
        font-weight: 600;
    }
}

.parallax {
    left: -1px!important;
}
.service-repair-btn {
    margin: 0 auto;
    display: block;
}
.light-gray-kamat {
    background-color:#e3e3e3;
    h2 {
        color:#111;
    }
}
body.home .location-services {
    display: none;
}
.gform_wrapper textarea {
    resize: vertical!important;
}

.flex-centered{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: center;
    flex-wrap: wrap;
    align-self: center;
}
.flex-toggle:nth-of-type(even){
    .container{
        .row{
            @media (max-width: $breakpoint-992) {
                flex-direction: column-reverse!important;
            }
        }
    }
}

////////////////
// Order Form //
////////////////

form.order-form {
    .gform_heading {
        width: 70%;
        .gform_description {
            ol {
                list-style-type: decimal!important;
                li {
                    font-weight: normal!important;
                    list-style-type: decimal!important;
                    font-size: 20px!important;
                }
            }
        }
    }
    .gform_body > ul {
        position: relative;
        .gfield.product, .gfield.product + li, .gfield.product + li + li {
            width: 70%;
            padding-right: 2%;
            display: inline-block;
            vertical-align: top;
            @media (min-width: $breakpoint-large) {
                width: 24%;
                padding-right: 2%;
                display: inline-block;
                vertical-align: middle;
            }
        }
        .gfield.product {
            input:checked {
                & + label {
                    pointer-events: none;
                    .add {
                        background-color: white;
                        padding: 0.5em 0.2em;
                        text-align: center;
                        color: #7ea97e;
                        text-transform: uppercase;
                        border: 1px solid #7ea97e;
                        transition: $global-transition;
                        &:before {
                            content: "";
                        }
                    }
                }
            }
            label {
                height: auto;
                padding: 0.5em!important;
                &:before, &:after {
                    display: none;
                }
                .add {
                    background-color: #7ea97e;
                    padding: 0.5em 0.2em;
                    text-align: center;
                    color: white;
                    text-transform: uppercase;
                    border: 1px solid transparent;
                    transition: $global-transition;
                    font-weight: bold;
                    margin-top: 2.5em;
                    &:hover {
                        opacity: 0.8;
                        transition: $global-transition;
                    }
                    &:before {
                        content: "+ ";
                    }
                }
            }
            .gfield_radio li:nth-of-type(2) {
                input:checked {
                    & + label {
                        display: none;
                    }
                }
                label {
                    padding-top: 0.75em!important;
                    color: white;
                    background-color: #7ea97e;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: bold;
                    transition: $global-transition;
                    &:hover {
                        opacity: 0.8;
                        transition: $global-transition;
                    }
                }
            }
            & > div > ul > li:nth-of-type(1) {
                & label {
                    color: #14426a;
                }
            }
            & > label {
                margin-bottom: 0!important;
                padding-bottom: 0!important;
                border: none!important;
                padding-left: 0.75em!important;
                &:first-of-type {
                    color: #14426a!important;
                }
            }
            .gfield_description {
                padding-left: 0.5em;
                width: 110%;
                @media (min-width: $breakpoint-large) {
                    width: 200%!important;
                }
                b {
                    font-size: 1.25em;
                    line-height: 1;
                }
                ul {
                    list-style-type: disc!important;
                    padding-left: 20px!important;
                    li {
                        list-style: disc!important;
                    }
                }
            }
            .ginput_container_radio, .ginput_container_select {
                max-width: 250px;
            }
        }
        .gfield_select {
            display: inline-block;
            width: 70%!important;
        }
        .gfield_quantity {
            @media (min-width: $breakpoint-large) {
                text-align: center;
                margin-top: 12em;
                & + .gfield {
                    margin-top: 12em;
                }
            }
        }
        .gfield_total {
            position: fixed;
            right: 0;
            left: 70%;
            background-color: white;
            padding: 1em;
            top: 40%;
            bottom: 60%;
            height: 120px;
            z-index: 9;
            box-shadow: 5px 8px 14px rgba(0, 0, 0, 0.2);
            border: 2px solid #7ea97e;
            margin-right: -1em;
            @media (min-width: $breakpoint-small) {
                position: fixed;
                left: 50%;
                right: inherit;
                margin-left: 25%!important;
                padding-right: 100vw;
                width: 115%;
            }
            & > * {
                margin: 0!important;
                vertical-align: top;
                float: left;
                width: 100%;
            }
            & > a {
                line-height: 1;
            }
        }
        .order-name {
            width: 70%;
            padding-right: 2%;
            display: inline-block;
            vertical-align: top;
            .name_first, .name_last {
                @media (max-width: $breakpoint-small) {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }
        .gfield input {
            color: black;
        }
        & > li:nth-last-of-type(-n+4):not(.gsection):not(.confirm) {
            select, input {
                width: 70%!important;
            }
            @media (min-width: $breakpoint-medium) {
                width: 35%!important;
                padding-right: 2%!important;
                display: inline-block;
                select, input {
                    width: 100%!important;
                }
            }
        }
        .gfield:not(.product) > .ginput_container_radio > ul {
            column-count: 2;
        }
    }
    .gform_footer input[type="submit"] {
        @extend .btn;
        @extend .btn-hover;
        @extend .orange;
        @extend .waves-effect;
        @extend .waves-light;
    }
}

input[name="gender"] {
    &:checked + label {
        font-weight: 500;
        background-color: rgba(126, 169, 126, 1)!important;
        transition: $global-transition;
        color: white;
        pointer-events: none;
        outline: 1px solid rgba(126, 169, 126, 1);
        &:before, &:after {
            display: none;
        }
    }
    + label {
        height: 40px!important;
        width: 100%;
        text-align: center;
        padding: 0!important;
        padding-top: 0.5em!important;
        padding-bottom: 0.5em!important;
        outline: 1px solid #CCC;
        line-height: 2!important;
        transition: $global-transition;
        font-weight: bold;
        text-transform: uppercase;
        &:before, &:after {
            display: none;
        }
        &:hover {
            background-color: rgba(126, 169, 126, 0.4);
            transition: $global-transition;
            color: white;
            outline: 1px solid rgba(126, 169, 126, 0.4);
        }
    }
}

section.gender-radio {
    position: fixed;
    width: 100%;
    background-color: white;
    padding: 1em 0;
    z-index: 5;
    box-shadow: 0 0px 15px 1px rgba(0,0,0,.2), 0 1px 4px rgba(0, 0, 0, 0.1);
    h4 {
        margin-bottom: 0.5em;
        text-align: center;
        @media (min-width: $breakpoint-medium) {
            margin: 0;
        }
    }
    .col {
        height: 40px;
    }
    & + .builder-grid {
        padding-top: 6em;
        @media (max-width: $breakpoint-medium) {
            padding-top: 8em;
        }
    }
}

.tile {
    overflow: hidden;
    position: relative;
    max-width: 100%;
    height: 230px;
    border: 1px solid transparent;
    transition: $global-transition;
    .photo {
        position: absolute;
        top: 10%;
        bottom: 10%;
        left: 10%;
        right: 10%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition: transform .5s ease-out;
    }
    &:hover {
        border-color: rgba(0, 0, 0, 0.2);
        transition: $global-transition;
        &:after {
            opacity: 0;
        }
    }
    @media (min-width: 1000px) {
        &:after {
            content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QzE1LjQxIDEyLjU5IDE2IDExLjExIDE2IDkuNSAxNiA1LjkxIDEzLjA5IDMgOS41IDNTMyA1LjkxIDMgOS41IDUuOTEgMTYgOS41IDE2YzEuNjEgMCAzLjA5LS41OSA0LjIzLTEuNTdsLjI3LjI4di43OWw1IDQuOTlMMjAuNDkgMTlsLTQuOTktNXptLTYgMEM3LjAxIDE0IDUgMTEuOTkgNSA5LjVTNy4wMSA1IDkuNSA1IDE0IDcuMDEgMTQgOS41IDExLjk5IDE0IDkuNSAxNHoiLz48cGF0aCBmaWxsPSJub25lIiBkPSJNMCAwaDI0djI0SDBWMHoiLz48cGF0aCBkPSJNMTIgMTBoLTJ2Mkg5di0ySDdWOWgyVjdoMXYyaDJ2MXoiLz48L3N2Zz4=");
            opacity: 0.75;
            position: absolute;
            top: 1.5%;
            right: 1.5%;
            transition: $global-transition;
        }
    }
}

.product-price {
    position: absolute;
    bottom: 1.75em;
    right: 0;
    left: 0;
    font-size: 2em;
    text-align: center;
    font-weight: 300!important;
}

.cart {
    margin-top: 2em;
    width: 70%;
}

#contact-info-fields {
    top: -25vh;
    position: relative;
    visibility: hidden;
}

.gfield.confirm {
    width: 100%!important;
    label {
        height: auto;
    }
}

.mobile-bottom-margin {
    @media (max-width: 992px) {
        margin-bottom: 1em;
    }
}

.seebach-bottom {
    @media (min-width: 1200px) {
        height: 550px;
        margin-bottom: 300px;
    }
}

.mine-wide img {
    margin-top: 2em;
    @media (min-width: 1200px) {
        margin-top: -120px;
    }
}

.home .darkgraytop {
    border-top-width: 25px!important;
    border-bottom-width: 25px!important;
}

.seebach-products {
    & > div {
        &:nth-of-type(1) {
            @media (min-width: 600px) and (max-width: 992px) {
                img {
                    transform: scale(0.85);
                }
            }
        }
        &:nth-of-type(2), &:nth-of-type(3) {
            img {
                max-width: 80%;
                display: block;
                margin: 0.75em auto 1.5em;
            }
        }
        @media (min-width: 992px) {
            &:nth-of-type(1) {
                img {
                    transform: scale(1.55);
                    margin: 30px auto;
                    z-index: 9;
                    position: relative;
                }
            }
            &:nth-of-type(2) {
                img {
                    max-width: 175%;
                    top: -42px;
                    display: block;
                    position: relative;
                    left: 25px;
                    margin: initial;
                }
            }
            &:nth-of-type(3) {
                img {
                    max-width: 90%;
                    top: -61px;
                    display: block;
                    position: relative;
                    left: 120px;
                    margin: initial;
                }
            }
        }
    }
}

#kamat, .kamat-logo {
    @media (max-width: 600px) {
        margin: 0.75em auto;
        display: block;
    }
}

$swanson-blue: #14426a;

.swanson-blue {
	color: $swanson-blue!important;
}

/////////////////////
// Longwall Shield //
/////////////////////


%markers {
    @media (max-width: 992px) {
	    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.9));
	}
	a {
		display: block;
		width: 100%;
		height: 100%;
		z-index: 2;
		position: relative;
		cursor: pointer;
	}
	.inner-circle {
		background-image: url("img/circle-transparent.svg");
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		animation: pulse 2.5s ease-in-out infinite;
		z-index: 1;
		pointer-events: none;
		background-position: top center;
		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
	}
}

.longwall-shield {
    padding: 2em 0;
    & > div {
    	margin: 0 auto;
    	display: block;
    	margin-top: 1em!important;
    	text-align: center;
    	max-width: 1500px;
    	& > a {
    		font-family: 'Industry';
    		margin: 0 0.25em;
    	}
    }
}

.longwall-shield > div {
	position: relative;
	& > img {
		width: 100%;
		height: auto;
		max-width: initial;
		position: relative;
		margin-top: 2em;
	}
	.marker-1 {
		height: 8%;
		width: 7%;
		position: absolute;
		top: 23%;
		right: 12%;
		background-image: url("img/circle.svg");
		background-position: top center;
  		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		@extend %markers;
		.inner-circle {
			animation-delay: 0.2s;
		}
		@media (max-width: 992px) {
		    height: 10%;
		    width: 9%;
		}
	}
	.marker-2 {
		height: 8%;
		width: 7%;
		position: absolute;
		top: 41%;
		right: 72%;
		background-image: url("img/circle.svg");
		background-position: top center;
  		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		@extend %markers;
		.inner-circle {
			animation-delay: 0.4s;
		}
		@media (max-width: 992px) {
		    height: 10%;
		    width: 9%;
		}
	}
	.marker-3 {
		height: 8%;
		width: 7%;
		position: absolute;
		top: 53%;
    	right: 70.5%;
		background-image: url("img/circle.svg");
		background-position: top center;
  		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		@extend %markers;
		.inner-circle {
			animation-delay: 0.6s;
		}
		@media (max-width: 992px) {
		    height: 10%;
		    width: 9%;
		}
	}
	.marker-4 {
		height: 8%;
		width: 7%;
		position: absolute;
		top: 63%;
    	right: 76%;
		background-image: url("img/circle.svg");
		background-position: top center;
  		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		@extend %markers;
		.inner-circle {
			animation-delay: 0.8s;
		}
		@media (max-width: 992px) {
		    height: 10%;
		    width: 9%;
		}
	}
	.marker-5 {
		height: 8%;
		width: 7%;
		position: absolute;
		top: 56.5%;
    	right: 90%;
		background-image: url("img/circle.svg");
		background-position: top center;
  		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		@extend %markers;
		.inner-circle {
			animation-delay: 1s;
		}
		@media (max-width: 992px) {
		    height: 10%;
		    width: 9%;
		}
	}
	.marker-6 {
		height: 8%;
		width: 7%;
		position: absolute;
		top: 82%;
    	right: 48.5%;
		background-image: url("img/circle.svg");
		background-position: top center;
  		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		@extend %markers;
		.inner-circle {
			animation-delay: 0.3s;
		}
		@media (max-width: 992px) {
		    height: 10%;
		    width: 9%;
		}
	}
	.marker-7 {
		height: 8%;
		width: 7%;
		position: absolute;
		top: 70%;
    	right: 60%;
		background-image: url("img/circle.svg");
		background-position: top center;
  		background-repeat: no-repeat;
		background-size: contain;
		transform-origin: center;
		@extend %markers;
		.inner-circle {
			animation-delay: 1.75s;
		}
		@media (max-width: 992px) {
		    height: 10%;
		    width: 9%;
		}
	}
}


///////////
// Popup //
///////////


.info-popup {
	position: absolute;
    top: 0;
    margin: auto;
	bottom: 0;
	box-shadow: 0px 1px 22px 10px rgba(0, 0, 0, 0.6), 0px 1px 4px rgba(0, 0, 0, 0.25);
	background-color: rgba(248, 248, 248, 0.96);
	border-radius: 2px;
	transition: all 0.35s ease-in-out;
	display: table;
	z-index: 10;
	pointer-events: none;
	text-align: left;
	border: 2px solid rgba(248, 248, 248, 0.96);
	&.right {
		transform-origin: right center;
		right: 4em;
	}
	&.righttop {
		transform-origin: right top;
		right: 4em;
		top: 6em;
	}
	&.left {
		transform-origin: left center;
		left: 4em;
	}
	&.lefttop {
		transform-origin: left bottom;
		left: 4em;
		bottom: 12em;
		&.slight {
			bottom: 4em;
		}
	}
	& > div {
		padding: 1em;
		&.vertical {
			display: table-cell;
			vertical-align: top;
		}
	}
	& > img {
		width: 100%;
		height: auto;
		&.vertical {
			min-width: 250px !important;
			min-height: 350px;
			display: table-cell;
		}
		&:not(.vertical) {
		    min-width: 350px;
		}
	}
	p {
		margin-bottom: 0!important;
	}
	h3 {
		@extend .swanson-blue;
	}
}

.bounce-animation {
	animation: popup 0.5s 1 running;
}

.longwall-subtitle {
	display: block;
	margin-top: 1em;
	p {
		text-align: center;
		margin: 0 auto;
		padding: 0 2em;
		color: white!important;
	}
	& + div > .swanson-button {
	    @media (max-width: 992px) {
	        margin-bottom: 0.75em;
	    }
	}
}

@keyframes pulse {
	0% {
		opacity: 0;
		transform: scale(1);
	}
	15% {
		transform: scale(1.02);
	}

	30% {
		opacity: 1
	}

	85% {
		opacity: 0;
	}

	100% {
		opacity: 0;
		transform: scale(2.5);
	}
}

@keyframes popup {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	12% {
	transform: scale(0.05);
	opacity: 0;
	}

	24% {
	transform: scale(0.44);
	}

	70% {
	transform: scale(0.98);
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.btn.swanson-button {
    background-color: $swanson-blue !important;
	color: #fff !important;
	text-transform: uppercase;
	text-shadow: none;
	&:hover {
		background-color: lighten($swanson-blue, 10%)!important;
		transition: 0.25s ease-in-out!important;
	}
}

.rebuild-process {
    display: none;
    padding: 2em 0;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 1.5em;
}

.flipperAssemblyInfo, .waterSpraysInfo, .coatingCladdingInfo, .hoseRoutingInfo, .pinsInfo, .relayBarsInfo, .winchInfo {
    padding: 1.5em;
    display: inline-block;
    h3 {
        color: white;
        padding-left: 0.5em;
        text-align: left;
        margin-top: 0.25em;
        white-space: normal;
    }
    .vertical {
        max-width: 50%;
        max-height: 320px;
        width: auto;
        height: auto;
        display: inline-block;
        float: left;
    }
}

article#post-419 .builder-grid .row, body.page-id-1624 .builder-grid .row, .larger-margin.builder-grid .row, body.page-id-1560 .builder-grid:not(.weinhold-brochures) .row, body.page-id-404 .builder-grid.monorail-section .row {
    @media (min-width: 992px) {
        & > .col:first-of-type {
            padding-right: 1.5em;
        }
        & > .col:last-of-type {
            padding-left: 1.5em;
        }
    }
}

.builder-grid.black {
    img.ipad-center-icon {
        max-width: 90px!important;
    }
    h1 {
        margin-bottom: 0;
    }
    .row {
        padding: 2% 0;
    }
}

.contact-change > div > p {
    display: none;
}

.impact-link {
    color: $secondary-color!important;
    opacity: 1;
    transition: 0.25s ease-in-out;
    svg {
        height: 25px;
        vertical-align: text-top;
        transition: 0.25s ease-in-out;
        position: relative;
        display: inline-block;
        left: 0px;
        path {
            fill: $secondary-color;
        }
    }
    &:hover {
        opacity: 0.8;
        svg {
            left: 5px;
            transition: 0.25s ease-in-out;
        }
    }
}

footer#footer {
    z-index: 500;
    position: relative;
}

.au-valley {
    position: relative;
    padding: 4% 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5;
        background-image: url('img/topography.svg');
        background-position: center;
        background-size: 75%;
    }
    @media (min-width: 992px) {
        padding: 2% 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 5;
            background-image: url('img/topography.svg');
            background-position: center;
            background-size: 40%;
        }
    }
}

img.au-hunter-valley {
    @media (min-width: 992px) {
        margin-top: 0em;
        margin-bottom: -5em;
    }
    @media (min-width: 1350px) {
        margin-top: -5em;
        margin-bottom: -10em;
    }
}

.lorem {
    animation: 1s lorem infinite alternate ease-in-out;
}

@keyframes lorem {
    0% {
        color: red;
    }
    100% {
        color: orange;
    }
}

.col p a {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $swanson-blue;
    }
}

.supertee-card {
    box-shadow: $swanson-shadow;
    @media (min-width: 1250px) {
        margin-top: -3em;
    }
    div:first-of-type {
        text-align: center;
        background-color: #3c3f46;
        width: 100%;
        h4 {
            color: white!important;
            padding: 5%;
            margin: 0;
        }
    }
    div:not(:first-of-type) {
        padding: 3em;
        padding-bottom: 0;
        padding-top: 0;
    }
    div:nth-of-type(2) {
        padding: 0;
        margin-top: 1em;
        margin-bottom: 1em;
        display: inline-block;
        img {
            max-width: 50%;
            display: inline-block;
            max-height: 240px;
            @media (max-width: 570px) {
                padding: 2em 0;
            }
        }
        p {
            padding: 0 3em 1em 0;
            float: right;
            display: inline-block;
            width: 50%;
            line-height: 1.25;
        }
    }
    div:nth-of-type(3) {
        display: table;
        width: 100%;
        h4 {
            color: $swanson-blue;
        }
        tr:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }
        tr td:last-of-type {
            background-color: #e9f2ff;
        }
    }
    div:last-of-type {
        padding: 3em;
        padding-top: 2em;
        padding-bottom: 2em;
        a.btn {
            width: 100%;
        }
    }
}

body.page-template-page-au-mining-equipment-manufacturing main, body.page-template-page-deron main, body.page-template-page-deron-au main {
    .monorail-section {
        .row:nth-of-type(2) {
            @media (max-width: 992px) {
                flex-direction: column!important;
                .col:nth-of-type(2) {
                    .img-drop {
                        margin-top: 1em;
                    }
                }
            }
            .col:nth-of-type(2) {
                .img-drop img {
                    display: block;
                }
            }
        }
        h3 {
            @media (max-width: 992px) {
                margin-top: 1.5em;
            }
            & + p {
                margin-top: 0;
            }
        }
    }
    .weinhold-section {
        .row:nth-of-type(1) {
            @media (max-width: 992px) {
                flex-direction: column!important;
            }
        }
        .col:nth-of-type(1) {
            h3 {
                strong {
                    position: absolute;
                    top: 50%;
                    bottom: 25%;
                    transform: translateY(-50%);
                    padding-left: 1em;
                }
            }
        }
        .col:nth-of-type(2) {
            img {
                @media (max-width: 992px) {
                    max-height: 250px;
                    display: block;
                    margin: 0 auto;
                }
                max-height: 450px;
                @media (min-width: 1250px) {
                    max-height: 700px;
                    margin-top: -10em;
                    margin-bottom: -6em;
                }
            }
        }
    }
}

.deron-card-link {
    margin-top: 2em;
    padding: 3.4em;
    position: relative;
    div:first-of-type {
        width: 100%;
        text-align: center;
        svg {
            max-width: 200px;
        }
    }
    div {
        h4 {
            text-align: center;
            margin: 0.5em 0 0 0;
        }
    }
    a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.lighter-gray-bg-grad {
    background: rgb(245,245,245);
    background: -moz-linear-gradient(180deg, rgba(245,245,245,1) 5%, rgba(255,255,255,1) 95%);
    background: -webkit-linear-gradient(180deg, rgba(245,245,245,1) 5%, rgba(255,255,255,1) 95%);
    background: linear-gradient(180deg, rgba(245,245,245,1) 5%, rgba(255,255,255,1) 95%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f5f5f5",endColorstr="#ffffff",GradientType=1);
    padding: 6% 0;
}

.weinhold-brochures {
    margin-top: 2em;
    & > .container > .row {
        @media (max-width: 992px) {
            margin-bottom: .25em!important;
        }
        margin-bottom: 1.5em;
        & > .col {
            @media (max-width: 992px) {
                margin-top: 1.5em;
            }
        }
    }
    .card-drop .col:nth-of-type(2) {
        padding-left: 0!important;
    }
}

body.page-id-1645 section.builder-grid {
    h4 {
        margin: 1em 0;
    }
    svg {
        max-width: 350px;
        display: block;
        margin-bottom: 0.5em;
    }
}

.au-building-img {
    max-height: 220px;
    width: auto;
    @media (min-width: 992px) {
        transform: scale(1.5);
    }
}

footer.page-footer .info a {
    text-decoration: none!important;
    &:after {
        background-color: #818892!important;
    }
}

.grey {
    background: rgb(182,182,182);
    background: -moz-linear-gradient(300deg, rgba(182,182,182,1) 0%, rgba(158,158,158,1) 100%);
    background: -webkit-linear-gradient(300deg, rgba(182,182,182,1) 0%, rgba(158,158,158,1) 100%);
    background: linear-gradient(300deg, rgba(182,182,182,1) 0%, rgba(158,158,158,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b6b6b6",endColorstr="#9e9e9e",GradientType=1);
}

#capabilities-svg {
    max-width: 500px;
    float: right;
}

.light-gray-bg {
    background: rgb(240,240,240);
    background: -moz-linear-gradient(300deg, rgba(240,240,240,1) 0%, rgba(227,227,227,1) 100%);
    background: -webkit-linear-gradient(300deg, rgba(240,240,240,1) 0%, rgba(227,227,227,1) 100%);
    background: linear-gradient(300deg, rgba(240,240,240,1) 0%, rgba(227,227,227,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0f0f0",endColorstr="#e3e3e3",GradientType=1);
}

h3 ~ p {
    margin-top: 0;
}

.all-manufacturers {
    text-decoration: none!important;
    border-bottom: 1px solid $swanson-blue;
    transition: $global-transition;
}

#inline {
    h3 {
        color: $secondary-color;
    }
}

.au-intro {
    .col {
        @media (min-width: 992px) {
            &:first-of-type {
                padding-right: 1em;
            }
            &:last-of-type {
                padding-left: 1em;
            }
        }
    }
}

.why-work.container {
    .row.white-text {
        @media (min-width: 992px) {
            background-color: rgba(0,29,76,.72);
            padding: 2em 3.5em;
            border-radius: 8px;
            & > div.white-text {
                padding: 0 1em;
                &:first-of-type > p:first-of-type {
                    font-weight: bold;
                    font-size: 24px;
                }
            }
        }
    }
}

.drag-target {
    width: 0;
}

img[data-src] {
    opacity: 0;
}

img.lazyloaded {
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
}

#app-container {
    article {
        @media (max-width: $small-screen) {
            // bottom spacing on mobile
            margin-bottom: 1em;
        }

        // lists
        ul,
        ol.list {
            @extend .green;
            list-style-position: outside;
            padding: 0 0 0 25px;
            background-color: $color-primary-alt-table;
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 25px;

            li {
                padding: 1% 2%;
                border-left: 1px solid $color-primary-alt-table;
                margin: 0;
            }

            li:nth-of-type(odd) {
                background-color: lighten($color-primary-alt-table, 3%);
            }
        }

        table {
            list-style-position: outside;
            padding: 0 0 0 25px;
            background-color: $color-primary-alt-table;
            border-radius: 5px;
            overflow: hidden;
            border: none;
            margin-bottom: 4em;

            input {
                margin: 0;
                border-radius: 6px;
                padding: 0px 2%;
                height: 3rem;
                width: 100%;
                box-sizing: border-box;
                color: rgb(66, 66, 66)!important;
                letter-spacing: 0!important;
                border-bottom: none!important;
                transition: $global-transition;
                &:focus {
                    box-shadow: rgb(126, 169, 126) 0px 1px 0px 0px!important;
                }
            }

            tr {
                border-bottom: 1px solid $color-primary-alt-table;
                margin: 0;
            }

            tr:nth-of-type(odd) {
                background-color: lighten($color-primary-alt-table, 3%);
            }

            td {
                border: none !important;
                padding: 1% 2%;
                background: none;

                p {
                    margin: 0;
                }
            }
        }

        //article links
        a {
            color: $swanson-blue;
            text-decoration: none;
            font-weight: bold;
            border-bottom: 1px solid $secondary-color-2;
            /* These are technically the same, but use both */
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
            /* Adds a hyphen where the word breaks, if supported (No Blink) */
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
            transition: 0.1s ease-in-out;

            &:hover {
                color: lighten($swanson-blue, 5%);
                border-bottom: 1px solid transparent;
            }
        }

        h2 {
            margin-top: 0;
            font-weight: 700;
        }

        h3,
        h4 {
            margin-top: 0;
            margin-bottom: 0;
        }

        p {
            margin-top: 0;
        }

        table {
            width: 100%;

            td {
                border: 1px solid #505050;
                max-width: 50%;
                font-size: 16px;
            }
        }
    }
}