body {
    font-family: $font-body;
    font-size: $font-size;
    line-height: $line-height;
    color: #5f5f5f;
    font-weight: 300;
    line-height: 1.5!important;
    font-size: 18px;

    @media (min-width: $medium-screen) {
        font-size: 18px;
    }

    @media (min-width: $large-screen) {
        font-size: 20px;
        line-height: 1.5;
    }
}

h1, h2, h3, h4, h5, h6 {
	font-family: $heading-font;
	line-height: $line-height;
	overflow-wrap: break-word;
	margin-bottom: $typography-margin;
	margin-top: 0px;
}

// headings
h1 {
	font-size: $h1-size;
	line-height: $h1-line-height;
	font-weight: 900;
	color: $grey-dark;
	letter-spacing:-1.5px;
}
h2 {
	font-size: 42px;
	line-height: $h2-line-height;
	font-weight: 500;
	color: $swanson-blue;
	padding-top: 15px;
}

h3 {
	font-size: $h3-size;
	line-height: $h3-line-height;
	font-weight: 400;
	color: $swanson-blue;
}
h4 {
	font-size: $h4-size;
	font-weight: 400;
    color: $grey-dark;
    line-height: $h4-line-height;
}
h5 {
	font-size: $h5-size;
	font-weight: 400;
	color: $grey-dark;
	line-height: $h5-line-height;
}
h6 {
	font-size: $h6-size;
    color: $grey-dark;
    text-transform: uppercase !important;
    line-height: $h6-line-height;
}

// links
a {
    font-weight: 300;
    color: $primary-color;
    text-decoration: none;

    &:hover {
        color: $primary-color-dark;
    }
}


// media queries
@media #{$medium-and-down} {
	h1 {
		font-size: $h1-sm-size;
		line-height: $h1-sm-line-height;
		text-align: center;
	}
	h2 {
		font-size: $h2-sm-size;
		line-height: $h2-sm-line-height;
	}
	h3 {
		font-size: $h3-sm-size;
		line-height: $h3-sm-line-height;
	}
	h4 {
		font-size: $h4-sm-size;
		line-height: $h4-sm-line-height;
	}
	h5 {
		font-size: $h5-sm-size;
		line-height: $h5-sm-line-height;
	}
}